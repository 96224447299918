import React, { useEffect, useState } from "react";
import "./Payment.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

export default function CCASuccess() {
  const [searchParam] = useSearchParams();
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setMsg(searchParam.get("msg"));
  }, []);

  return (
    <>
      <section className="payment-section">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="payment-section-inner">
                <div className="payment-section-inner-head">
                  <h2>Payment Status</h2>
                </div>
                <div className="payment-grand-total">
                  <h4>{msg}</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
