import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const {isAuthenticated, user} = useSelector((state) => state.auth);
  if (!isAuthenticated)
    return <Navigate to="/Signin" />;
  else if (user.membershipExpired === 1) {
    return (
      <Navigate
        to={`/Payment?uid=${encodeURIComponent(user.payId)}&type=renew`}
      />
    );
  }
  return children;
};

export default PrivateRoute;
