import { createSlice } from "@reduxjs/toolkit";
import { LogoutUser } from "./authLocal";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  location: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.location = null;
      LogoutUser();
    },
    updateProfileImg: (state, action) => {
      state.user.profileImg = action.payload.profileImg;
      state.user.modifiedDate = action.payload.modifiedDate;
    },
    userLocation: (state, action) => {
      state.location = action.payload;
    }
  },
});

export const { loginSuccess, logout, updateProfileImg, userLocation } =
  authSlice.actions;
export default authSlice.reducer;
