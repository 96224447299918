import React from 'react'
import './WhyUs.css'; 
import { Col, Container, Row } from 'react-bootstrap'
import BookConsultation from '../../Components/BookConsultation/BookConsultation'
import WhyUsBnr from "../../images/why-bnr.png";
import Why4Col1 from "../../images/why-4col1.jpg";
import Why4Col2 from "../../images/why-4col2.jpg";
import Why4Col3 from "../../images/why-4col3.jpg";
import Why4Col4 from "../../images/why-4col4.jpg";
import Why3Col1 from "../../images/why-3col1.jpg";
import Why3Col2 from "../../images/why-3col2.jpg";
import Why3Col3 from "../../images/why-3col3.jpg";
import WhyusBtm from "../../images/why-btm.jpg";

export default function WhyUs() {
  return (
    <>
        <section className='why-bnr'>
            <Container fluid>
                <Row>
                    <Col lg={12} md={12} className='why-bnr-col'>
                        <img src={WhyUsBnr} alt="Tow To Tango" />
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section>
        <section>
            <Container>
                <Row>
                    <Col>
                        <div className='whyus-head'>
                            <div className='whyus-head-inner'>
                                <h2 className='text-styled' data-aos="zoom-in" data-aos-duration="500"><span>Love thrives on connections</span>, not numbers.</h2>
                            </div>
                        </div> 
                    </Col>
                </Row>
                <Row className='why-4col-row'>
                    <Col lg={3} md={6}>
                        <div className='why-4col'>
                            <div className='why-4col-img'>
                                <img src={Why4Col1} alt="Tow To Tango" data-aos="fade-in-up" data-aos-duration="500" />
                            </div>
                            <div className='why-4col-text'>
                                <div className='why-4col-text-info1' data-aos="fade-up" data-aos-duration="500">
                                    <p><span>41%</span> of dating app users mislead for appeal.</p>
                                </div>
                                <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    {/* <p>We cut through the clutter for you.</p> */}
                                    {/* <p>Authenticity and intent amongst our members lead to a rewarding experience.</p> */}
                                    <p>Authenticity is our forte.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className='why-4col'>
                            <div className='why-4col-img'>
                                <img src={Why4Col2} alt="Tow To Tango" data-aos="fade-in-up" data-aos-duration="500" />
                            </div>
                            <div className='why-4col-text'>
                                <div className='why-4col-text-info1' data-aos="fade-up" data-aos-duration="500">
                                    <p>Only <span>19%</span> secure second or third dates.</p>
                                </div>
                                <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>Our holistic process significantly boosts your chances of finding a compatible match.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className='why-4col'>
                            <div className='why-4col-img'>
                                <img src={Why4Col3} alt="Tow To Tango" data-aos="fade-in-up" data-aos-duration="500" />
                            </div>
                            <div className='why-4col-text'>
                                <div className='why-4col-text-info1' data-aos="fade-up" data-aos-duration="500">
                                    <p>Users usually spend <span>10</span> hours weekly on dating apps. </p>
                                </div>
                                <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>Your time deserves intentional, curated matches.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className='why-4col'>
                            <div className='why-4col-img'>
                                <img src={Why4Col4} alt="Tow To Tango" data-aos="fade-in-up" data-aos-duration="500" />
                            </div>
                            <div className='why-4col-text'>
                                <div className='why-4col-text-info1' data-aos="fade-up" data-aos-duration="500">
                                    <p>Dating apps leave <span>28%</span> users feeling frustrated, not hopeful.</p>
                                </div>
                                <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>With clarity and confidence, we cut through the clutter for you.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="why-3col-section">
            <Container>
                <Row>
                    <Col>
                        <div className='whyus-head whyus-head2'>
                            <div className='whyus-head-inner' data-aos="zoom-in" data-aos-duration="1000">
                                <h2><span>Unforgettable adventures</span>, at the touch of your fingertips </h2>
                                <small>Find the match you seek with three easy steps:</small>
                                {/* <small>Begin your 3-step matchmaking journey.</small> */}
                            </div>
                        </div> 
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={Why3Col1} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">1. Fill out the form:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Let the magic begin. This is where we get to know you better – your passions, interests, quirks, and what makes your heart skip a beat.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={Why3Col2} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">2. Connect with us:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Experience the value of connecting with your matchmaker, sharing your feedback and receiving our personalised support and guidance.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={Why3Col3} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">3. Meet compatible matches:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Now comes the best part – meeting your hand-picked compatible matches. We'll arrange introductions where sparks fly and laughter echoes with every electric connection.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <Row>
                    <Col>
                        <div className='whyus-btm'>
                            <img src={WhyusBtm} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
