import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "CCAvenue/";
const ccaAPIService = new APIService(baseUrl);

export async function CreateCheckout(uid, pid, type) {
  var url = `create-checkout?customerId=${encodeURIComponent(
    uid
  )}&packageIds=${pid}&type=${type}`;
  return await ccaAPIService.postData(url);
}
