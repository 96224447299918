import React from 'react'
import './FollowUs.css'; 
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import AnuFollower from "../../images/anu-follower.jpg";
import FbIcon from "../../images/fb-gradiant.png";
import InstaIcon from "../../images/insta-gradiant.png";
import InIcon from "../../images/in-gradiant.png";

export default function FollowUs() {
  return (
    <>
        <section className="membership-first-fold followus"> 
            <Container className='followus-container'>
                <Row>
                    <Col>
                        <h2 className='healine' data-aos="fade-right" data-aos-duration="1000"><span>Follow us</span></h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='followus-icon'>
                            <div className='followus-icon-col'>
                                <h4>Two 2 Tango</h4>
                            </div>
                            <div className='followus-icon-col'>
                                <ul>
                                    <li><Link><img src={FbIcon} alt="Tow To Tango" /></Link></li>
                                    <li><Link><img src={InstaIcon} alt="Tow To Tango" /></Link></li>
                                    <li><Link><img src={InIcon} alt="Tow To Tango" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='followus-inner-row'>
                    <Col lg={5} md={12} className="membership-first-fold-left-col">
                        <div className="membership-first-fold-left-col-inner">
                            <div className="membership-first-fold-left-col-inner-img">
                                <img src={AnuFollower} alt="Tow To Tango" data-aos="zoom-in-up" data-aos-duration="1000" />
                            </div> 
                        </div>
                    </Col>
                    <Col lg={7} md={12} className="membership-first-fold-right-col">
                        <div className="membership-first-fold-right-col-inner followus-right-inner">
                            <div className="membership-first-fold-right-col-inner-text followus-rightinner-text">
                                <h5 data-aos="fade-up" data-aos-duration="500">Anuradha Gupta</h5>
                                <p data-aos="fade-up" data-aos-duration="1000">Founder CEO & Lead Matchmaker</p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                Anuradha is an Indian-American based out of San Francisco, USA and New Delhi, India. She grew up in a small mountain town in India and has spent her formative years in the country. Over the last 25 years she has lived in Melbourne, London, Los Angeles, New York and New Delhi.
                                </p> 
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
