import { Form } from "react-bootstrap";

export function RenderCheckboxText(props) {
  return (
    <>
      {props.isReadOnly ? (
        <Form.Group
          key={"QCT_" + props.item.question_id}
          className="my-profile-info-form-row full-width-input"
          controlId={"Q_" + props.item.question_id}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>{props.item.question}</Form.Label>
          <div className="radios-btns-gender">
            {props.item.option != null &&
              props.item.option.split("~").map((o, i) => (
                <div key={`CT_${i}_${props.item.question_id}`}>
                  {props.item.option.split("~").length - 1 === i ? (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value
                            .split("~")
                            .some((part) => part.includes(o))
                        }
                        readOnly
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  ) : (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value.split("~").includes(o)
                        }
                        readOnly
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  )}
                </div>
              ))}
          </div>
          {props.item.answer.value != null &&
            props.item.answer.value.indexOf("Other") !== -1 && (
              <>
                <Form.Control
                  key={`QTO_${props.item.question_id}`}
                  type="text"
                  placeholder={props.item.question}
                  value={
                    props.item.answer.value == null
                      ? ""
                      : props.item.answer.value.split("^")[1] !== undefined
                      ? props.item.answer.value.split("^")[1].split("~")[0]
                      : ""
                  }
                  readOnly
                />
              </>
            )}
        </Form.Group>
      ) : (
        <Form.Group
          key={"QCT_" + props.item.question_id}
          className="my-profile-info-form-row full-width-input"
          controlId={"Q_" + props.item.question_id}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>{props.item.question}</Form.Label>
          <div className="radios-btns-gender">
            {props.item.option != null &&
              props.item.option.split("~").map((o, i) => (
                <div key={`CT_${i}_${props.item.question_id}`}>
                  {props.item.option.split("~").length - 1 === i ? (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value
                            .split("~")
                            .some((part) => part.includes(o))
                        }
                        onChange={(e) =>
                          props.handleCheckboxChange(e, props.item)
                        }
                        isInvalid={
                          props.item.answer.isInvalid &&
                          props.item.answer.mode !== "save"
                        }
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Please fill this field
                      </Form.Control.Feedback>
                    </Form.Check>
                  ) : (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="checkbox"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value.split("~").includes(o)
                        }
                        onChange={(e) =>
                          props.handleCheckboxChange(e, props.item)
                        }
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  )}
                </div>
              ))}
          </div>
          {props.item.answer.value != null &&
            props.item.answer.value.indexOf("Other") !== -1 && (
              <>
                <Form.Control
                  key={`QTO_${props.item.question_id}`}
                  type="text"
                  placeholder={props.item.question}
                  value={
                    props.item.answer.value == null
                      ? ""
                      : props.item.answer.value.split("^")[1] !== undefined
                      ? props.item.answer.value.split("^")[1].split("~")[0]
                      : ""
                  }
                  onChange={(e) => props.handleOtherText(e, props.item)}
                  isInvalid={
                    props.item.answer.isInvalid &&
                    props.item.answer.mode !== "save"
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please fill this field
                </Form.Control.Feedback>
              </>
            )}
        </Form.Group>
      )}
    </>
  );
}
