import React, { useEffect, useState } from "react";
import "./Payment.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetPackageByCurrency } from "../../apis/PackageAPI";
import CurrencyFormat from "react-currency-format";
import { ResolveBaseApiUrl } from "../../utils/Utility";
import { useSelector } from "react-redux";
import isoCountryCurrency from "iso-country-currency";
import axios from "axios";
import Loader from "../../Components/Spinner/Loader";
import { CreateCheckout } from "../../apis/CCAvenueAPI";
import { confirm } from "../../Components/ConfirmMessage/Confirmation";
import { GetMyMembershipInfo } from "../../apis/ProfileAPI";

export default function Payment() {
  const navigate = useNavigate();
  const { location } = useSelector((state) => state.auth);
  const [searchParam] = useSearchParams();
  const [userId, setUserId] = useState(searchParam.get("uid"));
  const [pid, setPid] = useState(searchParam.get("pid"));
  const [previd, setprevid] = useState(searchParam.get("ppid"));
  const [orderType, setOrderType] = useState(searchParam.get("type"));
  //console.log(userId);
  const [packages, setPackages] = useState([]);
  const [mandPackages, setMandPackages] = useState([]);
  const [optPackages, setOptPackages] = useState([]);
  const [currency, setCurrency] = useState("");
  const [pkgCurrency, setPkgCurrency] = useState("");

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [gstAmt, setGSTAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);

  const [updateMsg, setUpdateMsg] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const handleShowMessage = () => setShowMessage(true);
  const handleCloseMessage = () => {
    setShowMessage(false);
    navigate("/MyMembership");
  };

  const [disablePayButton, setDisablePayButton] = useState(true);
  const [showButton, setShowButton] = useState({
    stripe: false,
    ccavenue: false,
  });
  const [showLoader, setShowLoader] = useState({
    stripe: false,
    ccavenue: false,
  });

  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      var uid = userId.replace(/\+/g, "%2B");
      setUserId(uid);
    } else navigate("/Signup");
    
  }, []);

  useEffect(() => {
    if (orderType !== "new") GetMembership();
  }, [orderType]);

  useEffect(() => {
    if (location != null) {
      getCountry();
    }
  }, [location, pkgCurrency]);

  const GetMembership = async () => {
    var result = await GetMyMembershipInfo();
    setPkgCurrency(result.package_currency);
  };

  const handlePackage = (e) => {
    var pkgId = e.target.value;
    const isManPkg = mandPackages.some((p) => p.id.toString() === pkgId);
    const isOptPkg = optPackages.some((p) => p.id.toString() === pkgId);
    var pkgList = selectedPackage;
    if (isManPkg) {
      pkgList = [];
      pkgList.push(pkgId);
      setSelectedPackage(pkgList);
      hideOptionalPackage();
      var divId = `div_${pkgId}`;
      document.getElementById(divId).style.display = "block";
    }

    if (isOptPkg) {
      pkgList.push(pkgId);
      setSelectedPackage(pkgList);
    }
    calculateAmt(pkgList);
  };

  const getCountry = async () => {
    var currs = isoCountryCurrency.getAllInfoByISO(location.countryCode);
    currs.currency =
      orderType === "new" 
        ? currs.currency
        : pkgCurrency;
    // console.log(currs.currency);
    setCurrency(currs.currency);
    getPackages(currs);
  };

  const getPackages = async (currs) => {
    var pkg = await GetPackageByCurrency(
      currs.currency !== "INR" ? "USD" : "INR"
    );
    if (previd !== null && previd !== undefined) {
      var spid = previd.split(",");

      var dur = pkg
        .filter((x) => spid.includes(x.id.toString()))
        .map((x) => x.duration);
      if (orderType === "addon") {
        pkg = pkg.filter((x) => x.duration == dur[0]);
      } else if (dur[0] === "1") {
        setSelectedPackage(spid);
        pkg = pkg.filter((x) => parseInt(x.duration) > parseInt(dur[0]));
      }
    }
    setPackages(pkg);
    setMandPackages(pkg.filter((x) => x.optional === 0));
    setOptPackages(pkg.filter((x) => x.optional === 1));
    if (currs.currency === "INR")
      setShowButton({ stripe: false, ccavenue: true });
    else setShowButton({ stripe: true, ccavenue: false });
  };

  const calculateAmt = (pkglist) => {
    if (pkglist.length > 0) setDisablePayButton(false);
    else setDisablePayButton(true);

    var ttl = 0;
    var prevIds = [];
    if (previd != null) {
      prevIds = previd.split(",");
    }
    pkglist.forEach((p) => {
      var pkg = packages.filter((x) => x.id.toString() === p)[0];
      ttl = ttl + parseInt(pkg.packageValue);
    });

    if (currency === "INR") {
      var gst = ttl * 0.18;
      setGSTAmt(gst.toFixed(2));
      setTotalAmt(ttl + gst);
    } else setTotalAmt(ttl);
  };

  const hideOptionalPackage = () => {
    const elements = document.getElementsByClassName("opt-pkg");
    for (let i = 0; i < elements.length; i++) {
      var radio = elements[i].querySelector('input[type="radio"]');
      radio.checked = false;
      elements[i].style.display = "none";
    }
  };

  useEffect(() => {
    if (pid != null && packages.length > 0) {
      setSelectedPackage(pid.split(","));
      if (currency === "INR") handleCCAvenueCheckout();
      else if (currency === "USD") handleStripeCheckout();
    }
  }, [packages]);

  const handleStripeCheckout = async () => {
    var pkgId = orderType == "renew" ? pid.split(",") : selectedPackage;
    setShowLoader({ stripe: true, ccavenue: false });
    var url = `${ResolveBaseApiUrl()}Stripe/create-checkout-session?customerId=${encodeURIComponent(
      userId
    )}&packageIds=${pkgId.join(",")}&type=${orderType}`;

    try {
      const response = await axios.post(url);
      setShowLoader({ stripe: false, ccavenue: false });
      if (response.status === 200) {
        var result = response.data;
        if (result.item1 != null) window.location.href = result.item1;
        else {
          var msg = result.item2;
          switch (result.item2) {
            case "no_change":
              msg = "No change detected in package";
              break;
            case "package_updated":
              msg = "Membership updated successfully!";
              break;
            case "cannot_update_package":
              msg =
                "Membership can not be updated now. Consider downgrade your membership one week before renew is due or after expiry of this membership";
              break;
          }
          setUpdateMsg(msg);
          handleShowMessage();
          setShowLoader((prevState) => ({ ...prevState, stripe: false }));
        }
      } else {
        console.log("Location header not found in the response");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCCAvenueCheckout = async () => {
    setShowLoader({ stripe: false, ccavenue: true });
    var pkgId =
      orderType == "renew"
        ? pid !== null
          ? pid.split(",")
          : selectedPackage
        : selectedPackage;
    var result = await CreateCheckout(userId, pkgId.join(","), orderType);

    if (result.item1 !== null) {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = result.item1.checkoutUrl;

      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "encRequest";
      input.id = "encRequest";
      input.value = result.item1.encryptionRequest;
      form.appendChild(input);

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_code";
      input1.id = "access_code";
      input1.value = result.item1.accessCode;
      form.appendChild(input1);

      document.body.appendChild(form);
      form.submit();
    } else {
      var msg = result.item2;
      switch (result.item2) {
        case "no_change":
          msg = "No change detected in package";
          break;
        case "package_updated":
          msg = "Membership updated successfully!";
          break;
        case "cannot_update_package":
          msg =
            "Membership can not be updated now. Consider downgrading your membership one week before renew is due or after expiry of this membership";
          break;
      }
      setShowLoader((prevState) => ({ ...prevState, ccavenue: false }));
      setUpdateMsg(msg);
      handleShowMessage();
    }
  };

  const handleCheckout = async (e, mode) => {
    if (orderType.indexOf("new") === -1) {
      var msgBody =
        orderType === "addon" ? (
          <>
            Cupid's Concierge is valid through your subscription period. <br />
            Are you sure want to continue?{" "}
          </>
        ) : (
          <>
            <ul>
              <li>
                Upgrading existing membership with new package will lead to
                cancellation of existing package with no reimbursement. Please
                consider uprading the package after expiration or one week
                before the renew due date.
              </li>
            </ul>{" "}
            <p> Do you want to continue?</p>
          </>
        );
      if (await confirm(msgBody, "Yes", "No", { title: "Update Membership" })) {
        if (mode === "in") handleCCAvenueCheckout(e);
        if (mode === "us") handleStripeCheckout(e);
      }
    } else {
      if (mode === "in") handleCCAvenueCheckout(e);
      if (mode === "us") handleStripeCheckout(e);
    }
  };

  return (
    <>
      <section className="payment-section">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              {pid === null || pid === undefined ? (
                <div className="payment-section-inner">
                  <div className="payment-section-inner-head">
                    <h2>Packages</h2>
                  </div>
                  {mandPackages.length > 0 &&
                    mandPackages.map((pkg, i) => (
                      <div
                        className="payment-option-selected"
                        key={i}
                        style={{
                          display:
                            orderType === "addon" &&
                            previd === pkg.id.toString()
                              ? "none"
                              : "",
                        }}
                      >
                        <Form.Check inline type="radio" id={`pkg-${pkg.id}`}>
                          <Form.Check.Input
                            name="mand-package"
                            type="radio"
                            value={pkg.id}
                            onChange={handlePackage}
                            checked={selectedPackage.includes(
                              pkg.id.toString()
                            )}
                          />
                          <Form.Check.Label>
                            {pkg.packageName}
                            {" ("}
                            <CurrencyFormat
                              value={pkg.packageValue}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currency === "INR" ? "₹ " : "$ "}
                            />
                            {pkg.duration === "1"
                              ? " Monthly)"
                              : ` - ${pkg.duration} Months)`}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    ))}

                  {optPackages.length > 0 &&
                    optPackages.map((pkg, i) => (
                      <div
                        className="payment-option-selected opt-pkg"
                        key={i}
                        id={`div_${pkg.combinationPackageId}`}
                        style={{
                          display:
                            orderType === "addon" &&
                            previd === pkg.combinationPackageId.toString()
                              ? ""
                              : "none",
                        }}
                        checked={selectedPackage.includes(`'${pkg.id}'`)}
                      >
                        <Form.Check inline type="radio" id={`pkg-${pkg.id}`}>
                          <Form.Check.Input
                            name="opt-packages"
                            type="radio"
                            value={pkg.id}
                            onChange={handlePackage}
                          />
                          <Form.Check.Label>
                            {pkg.packageName}
                            {" ("}
                            <CurrencyFormat
                              value={pkg.packageValue}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currency === "INR" ? "₹ " : "$ "}
                            />
                            {pkg.duration === "1"
                              ? " Monthly)"
                              : ` - ${pkg.duration} Months)`}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    ))}
                  {currency === "INR" ? (
                    <div className="payment-option-selected">
                      <p>
                        GST (18%) -{" "}
                        {
                          <CurrencyFormat
                            value={gstAmt}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency === "INR" ? "₹ " : "$ "}
                          />
                        }
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="payment-grand-total">
                    <h4>Grand Total</h4>
                    <p>
                      <CurrencyFormat
                        value={totalAmt}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currency === "INR" ? "₹ " : "$ "}
                      />
                    </p>
                  </div>
                  <form>
                    <div className="payment-paynow">
                      {showButton.stripe ? (
                        <Button
                          type="button"
                          disabled={disablePayButton}
                          onClick={(e) => handleCheckout(e, "us")}
                        >
                          Pay Now
                          <Loader visible={showLoader.stripe} />
                        </Button>
                      ) : showButton.ccavenue ? (
                        <Button
                          type="button"
                          disabled={disablePayButton}
                          onClick={(e) => handleCheckout(e, "in")}
                        >
                          Pay Now
                          <Loader visible={showLoader.ccavenue} />
                        </Button>
                      ) : (
                        " "
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="payment-section-inner">
                  <div className="payment-section-inner-head">
                    <h2>Redirecting to payment page...</h2>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        className="upgrade-membership-modal"
        show={showMessage}
        onHide={handleCloseMessage}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Title>Update Membership</Modal.Title>
        <Modal.Body>{updateMsg}</Modal.Body>
      </Modal>
    </>
  );
}
