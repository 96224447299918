import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Misc/";

const miscAPIService = new APIService(baseUrl);

export async function BookConsultation(consultation) {
  let url = "bookconsultation";
  return await miscAPIService.postData(url, consultation);
}

export async function GetCountryList(){
  let url = 'getcountries';
  return await miscAPIService.fetchData(url);
}

export async function GetCurrencyList(){
  let url = 'getcurrencies';
  return await miscAPIService.fetchData(url);
}

export async function GetSalaryList(){
  let url = 'getsalaries';
  return await miscAPIService.fetchData(url);
}

export async function GetCities(country) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    iso2: `${country}`,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  var cities = [];

  await fetch(
    "https://countriesnow.space/api/v0.1/countries/cities",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      var data = result.data;
      data.forEach((element) => {
        cities.push({ name: element });
      });
    })
    .catch((error) => console.error(error));

  return cities;
}

export async function GetUserLocation() {
  return new Promise(async (resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude);
          // Fetch city and country based on coordinates
          try {
            const response = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            );
            const data = await response.json();
            console.log(data);
            resolve(data); // Resolve the promise with the fetched data
          } catch (error) {
            reject(error); // Reject the promise if there's an error
          }
        },
        async (error) => {
          try {
            const data = await GetUserIPDetail();
            resolve(data);
          } catch (ipError) {
            reject(ipError);
          }
        }
      );
    } else {
      try {
        const data = await GetUserIPDetail();
        resolve(data);
      } catch (ipError) {
        reject(ipError);
      }
    }
  });
}

async function GetUserIPDetail() {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return {
      countryName: data.country_name,
      countryCode: data.country,
      city: data.city,
    };
  } catch (error) {
    throw new Error("Failed to fetch IP details");
  }
}
