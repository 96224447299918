import { Form } from "react-bootstrap";

export function RenderText(props) {
  return (
    <>
      {props.isReadOnly ? (
        <Form.Group
          className="my-profile-info-form-row full-width-input"
          controlId={`Q_${props.item.question_id}`}
          key={`Q_${props.item.question_id}`}
        >
          <Form.Label column>{props.item.question}</Form.Label>
          <Form.Control
            type="text"
            placeholder={props.item.question}
            value={
              props.item.answer == "null" ||
              props.item.answer == null
                ? ""
                : props.item.answer
            }
            readOnly
          />
        </Form.Group>
      ) : (
        <Form.Group
          className="my-profile-info-form-row full-width-input"
          controlId={`Q_${props.item.question_id}`}
          key={`Q_${props.item.question_id}`}
        >
          <Form.Label column>{props.item.question}</Form.Label>
          <Form.Control
            type="text"
            placeholder={props.item.question}
            value={
              props.item.answer.value == "null" ||
              props.item.answer.value == null
                ? ""
                : props.item.answer.value
            }
            onChange={(e) => props.updateQnA(props.item, e.target.value, null)}
            isInvalid={
              props.item.answer.isInvalid && props.item.answer.mode !== "save"
            }
          />
          <Form.Control.Feedback type="invalid">
            Please fill this field
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </>
  );
}
