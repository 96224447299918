import React, { useEffect, useState } from "react";
import "./Login.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import LoginRightImg from "../../images/login-right-img.jpg";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import md5 from "md5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage";
import Loader from "../../Components/Spinner/Loader";
import { ActivateUser } from "../../apis/UserAPI";
import Cookies from "js-cookie";

export default function CreatePassword() {
  const [searchParam] = useSearchParams();
  const [token, setToken] = useState(searchParam.get("token"));
  const [validated, setValidated] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();

  const [changePasswordData, setChangePasswordData] = useState({
    newPassword: {
      value: "",
      show: true,
      invalid: false,
      invalidMsg: "Password required",
    },
    confirmPassword: {
      value: "",
      show: true,
      invalid: false,
      invalidMsg: "Confirm password required",
    },
  });

  const updateChangePasswordData = (
    key,
    value,
    isVisible,
    invalid,
    invalidMsg
  ) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/g;
    if (key === "newPassword" && !passwordRegex.test(value)) {
      invalid = true;
      invalidMsg =
        "Minimum eight and maximum ten characters, at least one uppercase letter, one lowercase letter, one number, and one special character";
    }
    if (
      key === "confirmPassword" &&
      changePasswordData.newPassword.value !== value
    ) {
      invalid = true;
      invalidMsg = "Password did not match";
    }
    setChangePasswordData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value,
        show: isVisible,
        invalid: invalid,
        invalidMsg: invalidMsg,
      },
    }));
  };

  const [alertData, setAlertData] = useState({
    variant: "",
    message: "",
    showAlert: false,
    date: Date.now(),
  });

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    Cookies.remove("userid");
    var t = token.replace(/\+/g, "%2B");
    setToken(t);
  }, []);

  const isFormValid = () => {
    for (const key in changePasswordData) {
      if (changePasswordData.hasOwnProperty(key)) {
        if (changePasswordData[key].invalid) return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableBtn(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false || !isFormValid()) {
      event.preventDefault();
      event.stopPropagation();
      setDisableBtn(false);
    } else {
      setShowLoader(true);
      var data = {
        token: token,
        password: md5(changePasswordData.newPassword.value),
      };

      var result = await ActivateUser(data);
      setShowLoader(false);
      if (result) navigate("/signin");
      else
        setAlertData({
          variant: "Dark",
          message: "Token expired. Please contact admin.",
          showAlert: true,
          date: Date.now(),
        });
    }
  };

  return (
    <>
      <section className="login">
        <AlertMessage
          variant={alertData.variant}
          message={alertData.message}
          show={alertData.showAlert}
          delay={3000}
          date={alertData.date}
        />
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-left signup-form">
                <h2 data-aos="zoom-in" data-aos-duration="1000">
                  Create Password
                </h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="NewPassword"
                      label="New Password"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="New Password"
                        value={changePasswordData.newPassword.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "newPassword",
                            e.target.value,
                            true,
                            false
                          )
                        }
                        isInvalid={changePasswordData.newPassword.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.newPassword.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="ConfirmPassword"
                      label="Confirm Password"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm Password"
                        value={changePasswordData.confirmPassword.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "confirmPassword",
                            e.target.value,
                            true,
                            false
                          )
                        }
                        isInvalid={changePasswordData.confirmPassword.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.confirmPassword.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Row>
                  <Button
                    className="login-btn"
                    type="submit"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    disabled={disableBtn}
                  >
                    CREATE
                    <Loader visible={showLoader} />
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="login-right">
                <img src={LoginRightImg} alt="Tow To Tango" />
              </div>
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
    </>
  );
}
