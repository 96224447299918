export function StoreLocalUser(userid, payid, name, gender, img, modifiedOn, membershipExpired, token) {
  localStorage.clear();
  localStorage.setItem("userId", userid);
  localStorage.setItem("payId", payid);
  localStorage.setItem("userName", name);
  localStorage.setItem("userGender",gender);
  localStorage.setItem("profileImg", img);
  localStorage.setItem("modifiedDate",modifiedOn);
  localStorage.setItem("membershipExpired", membershipExpired);
  localStorage.setItem("token", token);
}

export function LogoutUser() {
  localStorage.clear();
  window.location.href= "/Signin";
  // window.location.reload();
}

export function GetLocalUser() {
  const data = {
    userId: localStorage.getItem("userId"),
    payId: localStorage.getItem("payId"),
    userName: localStorage.getItem("userName"),
    userGender: localStorage.getItem("userGender"),
    profileImg: localStorage.getItem("profileImg"),
    modifiedDate: localStorage.getItem("modifiedDate"),
    membershipExpired: localStorage.getItem("membershipExpired"),
    token: localStorage.getItem("token"),
  };
  return data;
}

export function UpdateProfileImg(img){
  localStorage.setItem("profileImg", img);
}


