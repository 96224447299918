import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { GetUserSnapshot } from "../../apis/ProfileAPI";
import "./MyProfile.css";
import dateFormat from "dateformat";
import {
  GetCountryList,
  GetCurrencyList,
  GetSalaryList,
} from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";
import { RenderText } from "../../Components/FormInputs/RenderText";
import { RenderRadioText } from "../../Components/FormInputs/RenderRadioText";
import { RenderTextarea } from "../../Components/FormInputs/RenderTextarea";
import { RenderRadio } from "../../Components/FormInputs/RenderRadio";
import { RenderCheckbox } from "../../Components/FormInputs/RenderCheckbox";

export default function Snapshot(props) {
  const [profileData, setProfileData] = useState({
    user_id: 0,
    user_first_name: "",
    user_last_name: "",
    user_age: "",
    user_gender: "",
    user_dob: "",
    user_birth_place: "",
    user_address: "",
    user_city: "",
    userQnA: [],
  });

  const [currencyList, setCurrencyList] = useState([]);
  const [allSalaries, setAllSalaries] = useState([]);
  const [countries, setCountries] = useState([]);

  const feet = ["4 feet", "5 feet", "6 feet", "7 feet"];
  const inches = [
    "0 inch",
    "1 inch",
    "2 inches",
    "3 inches",
    "4 inches",
    "5 inches",
    "6 inches",
    "7 inches",
    "8 inches",
    "9 inches",
    "10 inches",
    "11 inches",
  ];

  const start = 18;
  const end = 70;

  // Create an array with numbers from start to end
  const ageArray = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  const GetCurrencies = async () => {
    var data = await GetCurrencyList();
    setCurrencyList(data);
  };

  const GetSalaries = async () => {
    var data = await GetSalaryList();
    setAllSalaries(data);
  };

  const updateProfileAboutMeData = (propName, propValue) => {
    setProfileData((prevState) => ({
      ...prevState,
      [propName]: propValue,
    }));
  };

  const GetCountries = async () => {
    var data = await GetCountryList();
    setCountries(data);
  };

  useEffect(() => {
    const GetAbtMe = async () => {
      var data = await GetUserSnapshot(0);
      let basicData = data.item1;
      let aboutMeQnA = data.item2;

      for (var prop in basicData) {
        if (!basicData.hasOwnProperty(prop)) continue;
        updateProfileAboutMeData(prop, basicData[prop]);
      }
      updateProfileAboutMeData("userQnA", aboutMeQnA);
    };
    GetCurrencies();
    GetSalaries();
    GetCountries();
    GetAbtMe();
  }, [props.snapshot]);

  const handleSelectedCountries = (item) => {
    if (item.answer !== null && item.answer !== "") {
      var ids = item.answer.split(",");
      var c = countries.filter((x) => ids.includes(x.country_id.toString()));
      return c;
    } else return [];
  };

  return (
    <div className="my-profile-info">
      <Form>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="FirstName"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            readOnly
            value={profileData.user_first_name}
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="LastName"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            value={profileData.user_last_name}
            readOnly
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="UserAge"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Age</Form.Label>
          <Form.Control
            type="text"
            placeholder="Age"
            value={profileData.user_age}
            readOnly
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="DateOBirth"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Date of Birth</Form.Label>
          <Form.Control
            type="text"
            placeholder="Date of Birth"
            readOnly
            value={dateFormat(profileData.user_dob, "mmm dS, yyyy")}
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="PlaceBirth"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Place of Birth</Form.Label>
          <Form.Control
            type="text"
            placeholder="Place of Birth"
            value={profileData.user_birth_place ?? ""}
            readOnly
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row full-width-input"
          controlId="CurrentResidentialAddress"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Location"
            value={profileData.user_address}
            readOnly
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="UserCity"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>City currently living in</Form.Label>
          <Form.Control
            type="text"
            placeholder="City"
            value={profileData.user_city}
            className="non-edit"
            readOnly
          />
        </Form.Group>
        <Form.Group
          className="my-profile-info-form-row"
          controlId="Gender"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column>I am a</Form.Label>
          <div className="radios-btns-gender">
            <Form.Check
              inline
              label="Man"
              name="Gender"
              type="radio"
              id="Male"
              value="male"
              checked={profileData.user_gender == "male"}
              readOnly
            />
            <Form.Check
              inline
              label="Woman"
              name="Gender"
              type="radio"
              id="Female"
              value="female"
              checked={profileData.user_gender == "female"}
              readOnly
            />
          </div>
        </Form.Group>
        {profileData.userQnA.map((item, indx) =>
          item.field_type === "text" ? (
            <RenderText item={item} isReadOnly={true} />
          ) : item.field_type === "select_2" ? (
            item.question_id === 2 ? (
              <Form.Group
                className="my-profile-info-form-row"
                controlId={"Q_" + item.question_id}
                data-aos="fade-up"
                data-aos-duration="1000"
                key={"Q_" + item.question_id}
              >
                <Form.Label column key={"QL_" + item.question_id}>
                  {item.question}
                </Form.Label>
                <Form.Select
                  key={"QS_" + item.question_id}
                  aria-label={item.question}
                  size="sm"
                  value={
                    item.answer == null
                      ? ""
                      : `${item.answer.split(".")[0]} feet`
                  }
                  disabled
                >
                  <option key="feet">Feet</option>
                  {feet.map((f, i) => (
                    <option key={"QS_" + i}>{f}</option>
                  ))}
                </Form.Select>{" "}
                <Form.Select
                  key={"QS1_" + item.question_id}
                  aria-label={item.question}
                  size="sm"
                  value={
                    item.answer == null
                      ? ""
                      : item.answer.split(".")[1] == 0 ||
                        item.answer.split(".")[1] == 1
                      ? `${item.answer.split(".")[1]} inch`
                      : `${item.answer.split(".")[1]} inches`
                  }
                  disabled
                >
                  <option key="inch">Inches</option>
                  {inches.map((f, i) => (
                    <option key={"QS0_" + i}>{f}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : item.question_id === 21 ? (
              <Form.Group
                className="my-profile-info-form-row"
                controlId={"Q_" + item.question_id}
                data-aos="fade-up"
                data-aos-duration="1000"
                key={"Q_" + item.question_id}
              >
                <Form.Label column key={"QL_" + item.question_id}>
                  {item.question}
                </Form.Label>
                <Form.Select
                  key={"QS_" + item.question_id}
                  aria-label={item.question}
                  size="sm"
                  value={
                    item.answer == null ? "" : `${item.answer.split(",")[0]}`
                  }
                  disabled
                >
                  <option key="from">From</option>
                  {ageArray.map((f, i) => (
                    <option key={"QS_" + i}>{f}</option>
                  ))}
                </Form.Select>
                {"–"}
                <Form.Select
                  key={"QS1_" + item.question_id}
                  aria-label={item.question}
                  size="sm"
                  value={
                    item.answer == null ? "" : `${item.answer.split(",")[1]}`
                  }
                  disabled
                >
                  <option key="to">To</option>
                  {ageArray.map((f, i) => (
                    <option key={"QS0_" + i}>{f}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : item.question_id === 40 ? (
              <Form.Group
                className="my-profile-info-form-row full-width-input"
                controlId={"Q_" + item.question_id}
                key={"QS2_" + item.question_id}
              >
                <Form.Label column>{item.question}</Form.Label>
                <div className="currency-select">
                  <Form.Select
                    aria-label={item.question}
                    size="sm"
                    value={
                      item.answer == null ? "" : `${item.answer.split("~")[0]}`
                    }
                    disabled
                  >
                    <option key="currency">Currency</option>
                    {currencyList.map((curr, i) => (
                      <option value={curr.enr_name}>{curr.enr_name}</option>
                    ))}
                  </Form.Select>{" "}
                  <Form.Select
                    aria-label={item.question}
                    size="sm"
                    value={
                      item.answer == null ? "" : `${item.answer.split("~")[1]}`
                    }
                    disabled
                  >
                    <option key="salary">Salary</option>
                    {allSalaries.map((sal, i) => (
                      <option value={sal.salary_id} key={`sal_opt_${i}`}>
                        {sal.salary_slab}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            ) : (
              ""
            )
          ) : item.field_type === "radio_text" ? (
            <RenderRadioText item={item} isReadOnly={true} />
          ) : item.field_type === "textarea" ? (
            <RenderTextarea item={item} isReadOnly={true} />
          ) : item.field_type === "radio" ? (
            <RenderRadio item={item} isReadOnly={true} />
          ) : item.field_type === "select" ? (
            <Form.Group
              key={"QTA_" + item.question_id}
              className="my-profile-info-form-row full-width-input"
              controlId={"QTA_" + item.question_id}
            >
              <Form.Label column>{item.question}</Form.Label>
              <Typeahead
                multiple
                id="membership_country"
                labelKey="name"
                options={countries}
                placeholder={item.question}
                selected={handleSelectedCountries(item)}
                disabled
              />
            </Form.Group>
          ) : item.field_type === "checkbox" ? (
            <RenderCheckbox item={item} isReadOnly={true} />
          ) : item.field_type === "radio_textarea_radio" ? (
            <>
              <Form.Group
                key={"QRT_" + item.question_id}
                className="my-profile-info-form-row full-width-input"
                controlId={"Q_" + item.question_id}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Form.Label column>{item.question}</Form.Label>
                <div className="radios-btns-gender">
                  {item.option != null &&
                    item.option.split("~").map((o, i) => (
                      <div key={`RT_${i}_${item.question_id}`}>
                        {item.option.split("~").length - 1 === i ? (
                          <Form.Check
                            inline
                            name={`qo${i}${item.question_id}`}
                            id={`${i}${item.question_id}`}
                          >
                            <Form.Check.Input
                              type="radio"
                              value={o.split("^")[0]}
                              checked={
                                (item.answer != null &&
                                  item.answer == o.split("^")[0]) ||
                                (item.answer != null &&
                                  item.answer.indexOf("^") !== -1 &&
                                  item.answer.indexOf(
                                    o.split("^")[0].trim()
                                  ) !== -1)
                              }
                              readOnly
                            />
                            <Form.Check.Label>
                              {o.split("^")[0]}
                            </Form.Check.Label>
                          </Form.Check>
                        ) : (
                          <Form.Check
                            inline
                            name={`qo${i}${item.question_id}`}
                            id={`${i}${item.question_id}`}
                          >
                            <Form.Check.Input
                              type="radio"
                              value={o.split("^")[0]}
                              checked={
                                (item.answer != null &&
                                  item.answer == o.split("^")[0]) ||
                                (item.answer != null &&
                                  item.answer.indexOf("^") !== -1 &&
                                  item.answer.indexOf("Yes") !== -1)
                              }
                              readOnly
                            />
                            <Form.Check.Label>
                              {o.split("^")[0]}
                            </Form.Check.Label>
                          </Form.Check>
                        )}
                      </div>
                    ))}
                </div>
                {item.answer != null && item.answer.indexOf("Yes") !== -1 && (
                  <>
                    <Form.Control
                      as="textarea"
                      placeholder={item.question}
                      rows={3}
                      value={
                        item.answer == null ? "" : item.answer.split("^")[1]
                      }
                      readOnly
                    />
                  </>
                )}
              </Form.Group>
              {item.answer != null && item.answer.indexOf("Yes") !== -1 && (
                <Form.Group
                  key={"Q_" + item.option.split("^")[1]}
                  className="my-profile-info-form-row full-width-input"
                  controlId={"Q_" + item.option.split("^")[1]}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Form.Label column key={"QL_" + item.option.split("^")[1]}>
                    {
                      profileData.userQnA.filter(
                        (x) =>
                          x.question_id.toString() === item.option.split("^")[1]
                      )[0].question
                    }
                  </Form.Label>
                  <div className="radios-btns-gender">
                    {profileData.userQnA.filter(
                      (x) =>
                        x.question_id.toString() === item.option.split("^")[1]
                    )[0].option != null &&
                      profileData.userQnA
                        .filter(
                          (x) =>
                            x.question_id.toString() ===
                            item.option.split("^")[1]
                        )[0]
                        .option.split("~")
                        .map((o, i) => (
                          <div key={`QR_${i}_${item.option.split("^")[1]}`}>
                            {item.option.split("~").length - 1 === i ? (
                              <Form.Check
                                inline
                                name={`qo${i}${item.option.split("^")[1]}`}
                                id={`${i}${item.option.split("^")[1]}`}
                              >
                                <Form.Check.Input
                                  type="radio"
                                  value={o}
                                  checked={
                                    profileData.userQnA.filter(
                                      (x) =>
                                        x.question_id.toString() ===
                                        item.option.split("^")[1]
                                    )[0].answer != null &&
                                    profileData.userQnA.filter(
                                      (x) =>
                                        x.question_id.toString() ===
                                        item.option.split("^")[1]
                                    )[0].answer == o
                                  }
                                  readOnly
                                />
                                <Form.Check.Label>{o}</Form.Check.Label>
                              </Form.Check>
                            ) : (
                              <Form.Check
                                inline
                                name={`qo${i}${item.option.split("^")[1]}`}
                                id={`${i}${item.option.split("^")[1]}`}
                              >
                                <Form.Check.Input
                                  type="radio"
                                  value={o}
                                  checked={
                                    profileData.userQnA.filter(
                                      (x) =>
                                        x.question_id.toString() ===
                                        item.option.split("^")[1]
                                    )[0].answer != null &&
                                    profileData.userQnA.filter(
                                      (x) =>
                                        x.question_id.toString() ===
                                        item.option.split("^")[1]
                                    )[0].answer == o
                                  }
                                  readOnly
                                />
                                <Form.Check.Label>{o}</Form.Check.Label>
                              </Form.Check>
                            )}
                          </div>
                        ))}
                  </div>
                </Form.Group>
              )}
            </>
          ) : (
            ""
          )
        )}
      </Form>
    </div>
  );
}
