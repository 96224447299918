import React from 'react'
import './Notifications.css'; 

import { Col, Container, Row } from 'react-bootstrap'; 
import NewMatchesImg1 from "../../images/new-matches1.jpg";
import NewMatchesImg2 from "../../images/new-matches2.jpg";

export default function Notifications() {
  return (
    <>
        <section className='notifications-page'>
            <Container>
                <Row> 
                    <Col lg={12} md={12}>
                        <div className='home-notifications'>
                            <div className='home-notifications-inner'>
                                <h2 className='healine' data-aos="fade-right" data-aos-duration="1000"><span>Notifications</span></h2>
                                <div className='notifications-list'>
                                    <div className='notifications-list-item unread' data-aos="fade-up" data-aos-duration="1000">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg1} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            You have been matched with <strong>Mansi Sharma</strong>
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            February 21st, 2024
                                        </div>
                                        <div className='notifications-list-item-time'>
                                            2:34 pm
                                        </div>
                                    </div>
                                    <div className='notifications-list-item unread' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg2} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            There are people with similar interests as yours. Tap to see.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            February 7th, 2024
                                        </div>
                                        <div className='notifications-list-item-time'>
                                            8:20 pm
                                        </div>
                                    </div>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="2000">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg1} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            <strong>Alia Kapoor</strong> updated her profile picture.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            January 15th, 2024
                                        </div>
                                        <div className='notifications-list-item-time'>
                                            11:30 am
                                        </div>
                                    </div>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg2} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            <strong>Ananya Srivastav</strong> updated her bio.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            January 13th, 2024
                                        </div>
                                        <div className='notifications-list-item-time'>
                                            8:20 pm
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
