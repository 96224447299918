import React, { useEffect, useState } from "react";
import "./MyProfile.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import DocIcon from "../../images/doc-icon.png";
import CancelIcon from "../../images/cancel.png";
import Loader from "../../Components/Spinner/Loader";
import { AddDocument, DeleteDoc, GetAllDocs } from "../../apis/DocAPI";
import { GetBucketUrl } from "../../utils/Utility";
import { useSelector } from "react-redux";
import { FiletypePdf } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

export default function UploadDocuments() {
  const { user } = useSelector((state) => state.auth);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState("");
  const [base64String, setBase64String] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [docs, setDocs] = useState([]);
  const navigate = useNavigate();

  const maxFileSize = 5 * 1024 * 1024;

  function handleChange(e) {
    const selectedFile = e.target.files[0];
    setShowLoader(true);
    if (selectedFile) {
      setFileName(selectedFile.name);
      if (selectedFile.size > maxFileSize) {
        setError("File size must be less than 5MB.");
        setBase64String("");
        setShowLoader(false);
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError("Invalid file type. Only JPG, PNG, and PDF are allowed.");
        setBase64String("");
        setShowLoader(false);
        return;
      }

      setError("");

      const reader = new FileReader();
      reader.onloadend = () => {
        // const base64 = reader.result.split(",")[1];
        setBase64String(reader.result); // Set the Base64 string
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  useEffect(() => {
    if (base64String && base64String != "") {
      async function UploadDoc() {
        let data = {
          docId: 0,
          docName: fileName,
          docUrl: base64String,
          docDate: "",
        };

        await AddDocument(data);
        setShowLoader(false);
        setBase64String("");
        GetUsersDocs();
        document.getElementById("fileInput").value = "";
      }

      UploadDoc();
    }
  }, [base64String]);

  async function GetUsersDocs() {
    var result = await GetAllDocs();
    setDocs(result);
  }

  useEffect(() => {
    GetUsersDocs();
  }, []);

  const showDocument = (id) => {
    let doc = docs.filter((x) => x.docId === id);
    var url = `${GetBucketUrl()}${user.userId}/doc/${doc[0].docName}?modifed=${
      doc[0].docDate
    }`;

    window.open(url, "_blank");
  };

  const handleDeleteDoc = async (id) => {
    await DeleteDoc(id);
    GetUsersDocs();
  };

  return (
    <>
      <section className="upload-document">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div
                className="upload-document-close"
                onClick={() => navigate("/Myprofile")}
              >
                <img src={CancelIcon} alt="" />
              </div>
              <div className="upload-document-left">
                {/* <img src={file} /> */}
                <div className="document-item">
                  <img src={DocIcon} alt="" />
                  <div className="document-name">
                    <div className="document-name-inner">
                      <span>Upload Document</span>{" "}
                    </div>
                  </div>
                  <div className="document-input">
                    <input
                      id="fileInput"
                      type="file"
                      onChange={handleChange}
                      accept=".jpg,.jpeg,.png,.pdf"
                    />
                  </div>
                </div>
                <div className="document-info">
                  <p>
                    {
                      "Please upload government recognised Identity Proof (< 5MB)."
                    }
                  </p>
                  <span className="error" style={{ color: "red" }}>
                    {error}
                  </span>
                  <Loader visible={showLoader} />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            {docs.length > 0 &&
              docs.map((item, indx) => (
                <Col lg={3} md={4}>
                  <div className="upload-document-right">
                    <div className="upload-document-preview">
                      {item.docName.indexOf(".pdf") == -1 ? (
                        <img
                          src={`${GetBucketUrl()}${user.userId}/doc/${
                            item.docName
                          }?modifed=${item.docDate}`}
                          alt=""
                        />
                      ) : (
                        <FiletypePdf size="10em" />
                      )}
                    </div>
                    <div className="upload-document-name">
                      {item.docName.substring(0, item.docName.lastIndexOf("."))}
                    </div>
                    <div className="upload-document-action">
                      <Button onClick={() => showDocument(item.docId)}>
                        View
                      </Button>
                      <Button onClick={() => handleDeleteDoc(item.docId)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
