import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import "react-phone-number-input/style.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { ContactUs as cu } from "../../apis/ProfileAPI";
import Loader from "../../Components/Spinner/Loader";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { AddEnquiry } from "../../apis/UserAPI";
import { GetCities } from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";

export default function ContactUs() {
  const { isAuthenticated, user, location } = useSelector(
    (state) => state.auth
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showLoader, setShowLoader] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [hideSubmit, setHideSubmit] = useState({
    submit: false,
    submitted: true,
  });

  const validationRegex = {
    firstName: /^[a-zA-Z. ]{3,30}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
    age: /^(1[8-9]|[2-6][0-9]|70)$/,
  };

  const defaultContactData = {
    name: {
      value: isAuthenticated ? user.userName : "",
      invalid: false,
      internal: true,
    },
    emailId: { value: "", invalid: false, internal: false },
    phoneNo: { value: "", invalid: false, internal: false },
    age: { value: "", invalid: false, internal: false },
    gender: { value: "", invalid: false, internal: false },
    location: { value: "", invalid: false, internal: false },
    city: { value: "", invalid: false, internal: false },
    message: { value: "", invalid: true, internal: true },
    captcha: { value: "", invalid: false, internal: false },
  };

  const [contactUsData, setContactusData] = useState(defaultContactData);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [validated, setValidated] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const updateContactUsData = (key, value) => {
    if (value === undefined) value = "";
    setContactusData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value,
        invalid:
          key === "phoneNo" && value != "" && value != undefined
            ? isValidPhoneNumber(value, countryCode) === false
            : value == ""
            ? true
            : false,
      },
    }));
  };

  useEffect(() => {
    if (location != null) getCountry();
    loadCaptchaEnginge(6, "#CDAC45", "white");
  }, [location]);

  useEffect(() => {
    if (country != "" && country != null && country != undefined) {
      getCities();
    }
  }, [country]);

  const getCountry = async () => {
    updateContactUsData("location", location.city + ", " + location.country);
    setCountry(location.country);
    setCountryCode(location.countryCode);
  };

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const isObjectValid = (obj) => {
    let contactUsObj;

    if (isAuthenticated) contactUsObj = filterContactusData(obj, true);
    else contactUsObj = obj;

    for (const key in contactUsObj) {
      if (contactUsObj.hasOwnProperty(key)) {
        switch (key) {
          case "name":
            if (!validationRegex.firstName.test(contactUsObj[key].value))
              return false;
            break;
          case "emailId":
           // console.log(contactUsObj[key].value);
            if (!validationRegex.email.test(contactUsObj[key].value))
              return false;
            break;
          case "phoneNo":
            if (!isValidPhoneNumber(contactUsObj[key].value, countryCode)) {
              setPhoneValidated(false);
              return false;
            } else setPhoneValidated(true);
            break;
          case "age":
            if (!validationRegex.age.test(contactUsObj[key].value))
              return false;
            break;
          default:
            if (contactUsObj[key].invalid || contactUsObj[key].value === "") {
              updateContactUsData([key], contactUsObj[key].value);
              return false; // Invalid value found
            }
            break;
        }
      }
    }
    return true; // All properties have valid values
  };

  const filterContactusData = (obj, intrnal) =>
    Object.keys(obj).reduce((acc, key) => {
      if (obj[key].internal === intrnal) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});

  const handleContactUs = async (e) => {
    e.preventDefault();
    let userCaptcha = document.getElementById("captcha").value;
    setInitialLoad(false);
    if (validateCaptcha(userCaptcha) === true || isAuthenticated) {
      var valid = isObjectValid(contactUsData);
      if (valid) {
        let data = {
          id: 0,
          userName: contactUsData.name.value,
          emailId: contactUsData.emailId.value,
          phoneNo: contactUsData.phoneNo.value,
          age: contactUsData.age.value,
          gender: contactUsData.gender.value,
          location: contactUsData.location.value,
          city: contactUsData.city.value,
          message: contactUsData.message.value,
        };

        setShowLoader(true);
        if (isAuthenticated) await cu(data);
        else await AddEnquiry(data);
        setShowLoader(false);
        setHideSubmit({ submit: true, submitted: false });
        setContactusData(defaultContactData);
        setInitialLoad(true);
        updateContactUsData(
          "location",
          location.city + ", " + location.country
        );
        // if (result != 0) {
        //   handleShow();
        //
        // }
      }
    } else {
      updateContactUsData("captcha", "");
    }
    setValidated(false);
  };

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateContactUsData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateContactUsData("city", ""); // Clear city in contactUsData
    }
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(contactUsData.phoneNo.value, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  return (
    <>
      <section className="my-membership-section contact-us">
        <Container>
          <Row>
            <Col>
              <div className="contact-info-head">
                <h2
                  className="healine"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <span>We would love to hear from you</span>
                </h2>
                {/* <h4>Email us or reach us offline at:</h4>  */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <div className="contact-info">
                <div className="contact-info-row">
                  <div className="contact-info-col">
                    <div className="contact-info-col-inner">
                      <span>Email:</span>{" "}
                      <a href="mailto:contactus@two2tango.com">
                        contactus@two2tango.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-info-col">
                    <div className="contact-info-col-inner">
                      <span>London:</span>{" "}
                      <a href="tel:+447932303037">+44 7932303037</a>
                    </div>
                    {/* <div className="contact-info-col-inner">
                      <span>India:</span>{" "}
                      <a href="tel:+919821118813">+91 9821118813</a>
                    </div> */}
                    <div className="contact-info-col-inner">
                      <span>USA:</span>{" "}
                      <a href="tel:+19106440343">+1 (910) 644-0343</a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="my-membership-section-inner referrals-inner contact-us-inner">
                {/* <h2
                  className="healine"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <span>Contact us</span>
                </h2> */}
                <Form
                  noValidate
                  onSubmit={handleContactUs}
                  validated={validated}
                  autoComplete="off"
                >
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="Name"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Name:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        value={contactUsData.name.value}
                        onChange={(e) =>
                          updateContactUsData("name", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad &&
                          (contactUsData.name.invalid ||
                            !validationRegex.firstName.test(
                              contactUsData.name.value
                            ))
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {!isAuthenticated && (
                    <>
                      {" "}
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="emailid"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label column sm="12">
                          Email:
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={contactUsData.emailId.value}
                            onChange={(e) =>
                              updateContactUsData("emailId", e.target.value)
                            }
                            required
                            isInvalid={
                              !initialLoad &&
                              (contactUsData.emailId.invalid ||
                                !validationRegex.email.test(
                                  contactUsData.emailId.value
                                ))
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid email
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="phoneno"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label column sm="12">
                          Phone No.:
                        </Form.Label>
                        <Col sm="12">
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            value={contactUsData.phoneNo.value}
                            onChange={(e) => updateContactUsData("phoneNo", e)}
                            rules={{ required: true }}
                            onBlur={handlePhoneValidation}
                          />
                          {!initialLoad && !phoneValidated && (
                            <Form.Text className="text-danger">
                              Please provide valid phone number
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="age"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label column sm="12">
                          Age:
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control
                            type="number"
                            placeholder="Age"
                            value={contactUsData.age.value}
                            onChange={(e) =>
                              updateContactUsData("age", e.target.value)
                            }
                            required
                            isInvalid={
                              !initialLoad &&
                              (contactUsData.age.invalid ||
                                !validationRegex.age.test(
                                  contactUsData.age.value
                                ))
                            }
                            min={18}
                            max={70}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide valid age
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="gender"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label>Gender:</Form.Label>
                        <div className="radios-btns-gender">
                          <Form.Check
                            inline
                            label="Male"
                            name="Gender"
                            type="radio"
                            id="Male"
                            value="Male"
                            checked={contactUsData.gender.value === "Male"}
                            onChange={(e) =>
                              updateContactUsData("gender", e.target.value)
                            }
                            required
                          />
                          <Form.Check inline name="Gender" id="Female">
                            <Form.Check.Input
                              type="radio"
                              value="Female"
                              checked={contactUsData.gender.value === "Female"}
                              onChange={(e) =>
                                updateContactUsData("gender", e.target.value)
                              }
                              isInvalid={
                                !initialLoad &&
                                (contactUsData.gender.value == "" ||
                                  contactUsData.gender.invalid)
                              }
                            />
                            <Form.Check.Label>Female</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              Please fill this field
                            </Form.Control.Feedback>
                          </Form.Check>
                        </div>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="location"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label column sm="12">
                          Location:
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control
                            type="tel"
                            placeholder="Location"
                            value={contactUsData.location.value}
                            onChange={(e) =>
                              updateContactUsData("location", e.target.value)
                            }
                            required
                            isInvalid={
                              !initialLoad && contactUsData.location.invalid
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide location
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="my-membership-inout-row"
                        controlId="city"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        <Form.Label column sm="12">
                          City:
                        </Form.Label>
                        <Typeahead
                          id="consultation_city"
                          labelKey="name"
                          options={cities}
                          placeholder="city living in"
                          selected={selectedCity}
                          onChange={handleCityChange}
                          isInvalid={
                            !initialLoad &&
                            (!selectedCity ||
                              selectedCity.length === 0 ||
                              contactUsData.city.invalid)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide name of the city you live in
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}

                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="Message"
                  >
                    <Form.Label column sm="12">
                      Message
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        as="textarea"
                        rows={6}
                        value={contactUsData.message.value}
                        isInvalid={
                          !initialLoad && contactUsData.message.invalid
                        }
                        onChange={(e) =>
                          updateContactUsData("message", e.target.value)
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please type in message
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {!isAuthenticated && (
                    <Form.Group
                      as={Row}
                      className="my-membership-inout-row"
                      controlId="captcha"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <Form.Label column sm="12">
                        <LoadCanvasTemplateNoReload />
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          type="text"
                          placeholder="captcha"
                          value={contactUsData.captcha.value}
                          isInvalid={
                            !initialLoad && contactUsData.captcha.invalid
                          }
                          onChange={(e) =>
                            updateContactUsData("captcha", e.target.value)
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Invalid Captcha
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  )}

                  <div></div>
                  <div
                    className="membership-action-btns"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Button type="submit" hidden={hideSubmit.submit}>
                      Submit <Loader visible={showLoader} />
                    </Button>
                    <Button hidden={hideSubmit.submitted}>Submitted</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        className="upgrade-membership-modal"
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          Thank you for contacting us. We will get back to you shortly
        </Modal.Body>
      </Modal>
    </>
  );
}
