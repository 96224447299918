import { Button, Form } from "react-bootstrap";
import "./MyProfile.css";
import { useEffect, useState } from "react";
import { GetUserQnA, SaveQnA } from "../../apis/ProfileAPI";
import { GetCurrencyList, GetSalaryList } from "../../apis/MiscAPI";
import useInterval from "use-interval";
import { RenderText } from "../../Components/FormInputs/RenderText";
import { RenderRadioText } from "../../Components/FormInputs/RenderRadioText";
import { RenderTextarea } from "../../Components/FormInputs/RenderTextarea";

var changeDetectedProf = false;

export default function Profession(prop) {
  const [professionData, setProfessionData] = useState([]);
  const [autoSaveDelay] = useState(2 * 60 * 1000);
  const [disableBtn, setDisableBtn] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [allSalaries, setAllSalaries] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const defaultButtonText = {
    save: "Save",
    submit: "Submit",
  };
  const [buttonText, setButtonText] = useState(defaultButtonText);

  const GetData = async () => {
    var result = await GetUserQnA("profession", prop.userid ?? 0);
    const updatedQuestions = result.map((element) => ({
      ...element,
      answer: {
        value:
          element.field_type === "select_2"
            ? AnsweredCurrency(element.answer)
            : element.answer,
        isInvalid:
          element.answer === "" ||
          element.answer === null ||
          element.answer === "null",
        mode: "save",
      },
    }));
    setProfessionData(updatedQuestions);
  };

  const AnsweredCurrency = (v) => {
    if (v !== "") {
      var sal = allSalaries.filter((x) => x.salary_id.toString() === v);
      if (sal.length > 0) {
        var salList = allSalaries.filter(
          (x) => x.salary_type === sal[0].salary_type
        );
        setSalaryList(salList);
        setSelectedCurrency(sal[0].salary_type);
      }
    }
    return v;
  };

  const getCurrencies = async () => {
    var data = await GetCurrencyList();
    setCurrencyList(data);
  };

  const getSalaries = async () => {
    var data = await GetSalaryList();
    setAllSalaries(data);
  };

  useEffect(() => {
    getCurrencies();
    getSalaries();
    setButtonText(defaultButtonText);
  }, []);

  useEffect(() => {
    if (currencyList.length > 0 && allSalaries.length > 0) GetData();
  }, [currencyList, allSalaries]);

  const updateQnA = (qn, ans, mode = "save") => {
    if (prop.autoSaveMode.profession) changeDetectedProf = true;
    setProfessionData((prev) => {
      return prev.map((q) =>
        q.question_id === qn.question_id
          ? {
              ...q,
              answer: {
                ...q.answer,
                value: ans,
                isInvalid: ans === "" || ans === null,
                mode: mode,
              },
            }
          : q
      );
    });
  };

  const updateMode = (mode) => {
    const qnaData = [...professionData];
    // Update mode for userQnA
    if (qnaData) {
      if (Array.isArray(qnaData)) {
        // Handle array of questions
        qnaData.forEach((qn) => {
          qn.answer.mode = mode;
        });
      }
    }
    setProfessionData(qnaData);
  };

  const saveprofession = async (mode, type) => {
    if (type !== "auto")
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: mode === "save" ? "Saving..." : "Submitting...",
      }));
    var qna = [];
    professionData.forEach((item) => {
      qna.push({ QuestionId: item.question_id, Answer: item.answer.value });
    });
    const abtMe = {
      userId: 0,
      firstName: "",
      lastName: "",
      phoneNo: "",
      gender: "",
      birthPlace: "",
      address: "",
      city: "",
      qnA: qna,
    };
    if (mode === "save") {
      updateMode("save");
      await SaveQnA(abtMe);
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: "Saved",
      }));
      setDisableBtn(false);
      setDefaultButtonText();
    } else if (mode === "submit") {
      updateMode("submit");
      var isValid = isObjectValid(professionData);
      if (isValid) {
        await SaveQnA(abtMe);
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Submitted",
        }));
        setDefaultButtonText();
      } else {
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Fill all the fields.",
        }));
        setDefaultButtonText(5000);
      }
      setDisableBtn(false);
    }
  };

  const isObjectValid = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].answer.isInvalid) {
          return false;
        }
      }
    }
    return true; // All properties have valid values
  };

  useInterval(() => {
    if (prop.autoSaveMode.profession) {
      autoSaveData();
    }
  }, autoSaveDelay);

  function autoSaveData() {
    if (changeDetectedProf) {
      saveprofession("save", "auto");
      setButtonText((prevState) => ({ ...prevState, save: "Auto Saving..." }));
    }
  }

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e);
    var salList = allSalaries.filter((x) => x.salary_type === e);
    setSalaryList(salList);
  };

  const setDefaultButtonText = (t = 3000) => {
    changeDetectedProf = false;
    setTimeout(() => {
      setButtonText(defaultButtonText);
    }, t);
  };

  return (
    <div className="my-profile-info">
      <Form>
        {professionData.map((item, indx) =>
          item.field_type === "text" ? (
            <RenderText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "radio_text" ? (
            <RenderRadioText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "textarea" ? (
            <RenderTextarea item={item} updateQnA={updateQnA} />
          ) : item.field_type === "select_2" ? (
            <Form.Group
              className="my-profile-info-form-row full-width-input"
              controlId={"Q_" + item.question_id}
              key={"QS2_" + item.question_id}
            >
              <Form.Label column>{item.question}</Form.Label>
              <div className="currency-select">
                <Form.Select
                  aria-label={item.question}
                  size="sm"
                  value={selectedCurrency}
                  onChange={(e) => handleCurrencyChange(e.target.value)}
                >
                  <option key="currency">Currency</option>
                  {currencyList.map((curr, i) => (
                    <option value={curr.enr_name}>{curr.enr_name}</option>
                  ))}
                </Form.Select>{" "}
                <Form.Select
                  aria-label={item.question}
                  size="sm"
                  value={item.answer.value == null ? "" : item.answer.value}
                  onChange={(e) => updateQnA(item, e.target.value, null)}
                  isInvalid={
                    item.answer.isInvalid && item.answer.mode !== "save"
                  }
                >
                  <option key="salary">Salary</option>
                  {salaryList.map((sal, i) => (
                    <option value={sal.salary_id} key={`sal_opt_${i}`}>
                      {sal.salary_slab}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <Form.Control.Feedback type="invalid">
                Please fill this field
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ""
          )
        )}
        {!prop.userid && (
          <div className="submit-action-btns">
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => saveprofession("save")}
              disabled={disableBtn}
            >
              {buttonText.save}
            </Button>{" "}
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => saveprofession("submit")}
              disabled={disableBtn}
            >
              {buttonText.submit}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}
