import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Document/";

const docAPIService = new APIService(baseUrl);

export async function AddDocument(doc) {
  var url = "adduserdoc";
  return await docAPIService.postData(url, doc);
}

export async function DeleteDoc(docId) {
  var url = `deletedoc?docId=${docId}`;
  return await docAPIService.fetchData(url);
}

export async function GetAllDocs() {
  var url = "getuserdocs";
  return await docAPIService.fetchData(url);
}
