import { Toast, ToastContainer } from "react-bootstrap";
import "./AlertMessage.css";
import { useEffect, useState } from "react";

export const AlertMessage = ({ variant, message, delay, show, date }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    // console.log(message, show, date);
    setIsVisible(show);
  }, [date]);

  return (
    <ToastContainer
      className="p-3"
      position="middle-center"
      style={{ zIndex: 999 }}
    >
      <Toast
        className="d-inline-block m-1"
        bg={variant.toLowerCase()}
        show={isVisible}
        onClose={() => setIsVisible(false)}
        delay={delay}
        autohide
      >
        <Toast.Body className={variant === "Dark" && "text-white"}>
          {message}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
