import React, { useState } from 'react'
import './Referrals.css'; 
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'

export default function Referrals() {
    const [show, setShow] = useState(false); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <section className='my-membership-section'>
            <Container>
                <Row>
                    <Col>
                        <div className='my-membership-section-inner referrals-inner'>
                            <h2 className='healine' data-aos="zoom-in" data-aos-duration="500"><span>Referrals</span></h2>
                            <Form>
                                <Form.Group as={Row} className="my-membership-inout-row" controlId="Name" data-aos="fade-up" data-aos-duration="500">
                                    <Form.Label column sm="4">Name:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="text" placeholder="Full Name" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="my-membership-inout-row" controlId="Email" data-aos="fade-up" data-aos-duration="1000">
                                    <Form.Label column sm="4">Email:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="email" placeholder="Email@towtotango.com" />
                                    </Col>
                                </Form.Group> 
                                <Form.Group as={Row} className="my-membership-inout-row" controlId="Number" data-aos="fade-up" data-aos-duration="1500">
                                    <Form.Label column sm="4">Phone Number:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control type="number" placeholder="Phone Number" />
                                    </Col>
                                </Form.Group> 
                                <div className='membership-action-btns' data-aos="fade-up" data-aos-duration="2000">
                                    <Button onClick={handleShow}>Send By Email</Button>
                                    <Button>Share By Whatsapp</Button> 
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section> 

        <Modal className='upgrade-membership-modal' show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Title>Thank you for referring Two 2 Tango</Modal.Title>
            <Modal.Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</Modal.Body> 
        </Modal>
    </>
  )
}
