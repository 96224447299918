import React from 'react'
import './Media.css'; 
import { Col, Container, Row } from 'react-bootstrap';
import MediaImg1 from "../../images/media-img1.jpg";
import MediaImg2 from "../../images/media-img2.jpg";
import MediaImg3 from "../../images/media-img3.jpg";
import { Link } from 'react-router-dom';

export default function Media() {
  return (
    <>
        <section className='media-page'>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='media-inner'>
                            <h2 className='healine'><span>Media</span></h2>
                            <div className='media-inner-grid'>
                                <Row>
                                    <Col lg={4} md={6}>
                                        <div className='media-inner-col'>
                                            <div className='media-inner-img' data-aos="fade-up" data-aos-duration="500">
                                                <img src={MediaImg1} alt="TTT" />
                                            </div>
                                            <div className='media-inner-info'>
                                                <Link>Blog</Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className='media-inner-col'>
                                            <div className='media-inner-img' data-aos="fade-up" data-aos-duration="1000">
                                                <img src={MediaImg2} alt="TTT" />
                                            </div>
                                            <div className='media-inner-info'>
                                                <Link>Videos</Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <div className='media-inner-col'>
                                            <div className='media-inner-img' data-aos="fade-up" data-aos-duration="1500">
                                                <img src={MediaImg3} alt="TTT" />
                                            </div>
                                            <div className='media-inner-info'>
                                                <Link>Podcasts</Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col> 
                </Row>
            </Container>
        </section>
    </>
  )
}
