import configData from "../apis/config.json";

export function ResolveBaseApiUrl() {
    // const locality = configData && configData.locality;
    // //console.log(locality);
    // switch (locality) {
    //   case "local":
    //     return configData.apiLocalBaseUrl;
    //   case "qa":
    //     return configData.apiBaseUrl;
    //   case "staging":
    //     return configData.apiStagingBaseUrl;
    //   case "prod":
    //     return configData.apiProdBaseUrl;
    //   //default: return "/api/"; // staging  
    //   default: return "http://ec2-54-213-8-124.us-west-2.compute.amazonaws.com:8181/api/"; // qa
    //   //default: return "http://localhost:5036/api/"; // local
    // }

    return "/api/";
  }

  export function GetBucketUrl(){
    // const locality = configData && configData.locality;
    // switch (locality) {
    //   case "local":
    //     return configData.qas3bucketUrl;
    //   case "qa":
    //     return configData.qas3bucketUrl;
    //   case "staging":
    //     return configData.qas3bucketUrl;
    //   case "prod":
    //     return configData.s3bucketUrl;
    //   default: 
    return "https://two2tango.s3.us-west-2.amazonaws.com/"; // local
    //}
  }



