import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Package/";

const packageAPIService = new APIService(baseUrl);

export async function GetPackageByCurrency(currency) {
  let url = `getpackagebycurrency?currency=${currency}`;
  return await packageAPIService.fetchData(url);
}
