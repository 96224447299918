import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Photo/";

const photoAPIService = new APIService(baseUrl);

export async function GetUserPhoto(userid) {
  let url = `getuserphotos?userId=${userid}`;
  return await photoAPIService.fetchData(url);
}

export async function UploadUserPhoto(data) {
  let url = "uploaduserphoto";
  return await photoAPIService.postData(url, data);
}

export async function UpdateProfilePic(photoid){
  let url = `updateprofilepic?photoId=${photoid}`;
  return await photoAPIService.fetchData(url);
}

export async function DeletePic(photoid){
  let url = `deleteuserphoto?photoId=${photoid}`;
  return await photoAPIService.fetchData(url);
}

export async function RemoveProfilePic(){
  let url = "removeprofilepic";
  return await photoAPIService.fetchData(url);
}
