import React from 'react'
import './TermsConditions.css'; 
import { Col, Container, Row } from 'react-bootstrap'
import BookConsultation from '../../Components/BookConsultation/BookConsultation' 
import TcBnrImg from "../../images/tc-bnr-couple.png"; 
import { useParallax } from 'react-scroll-parallax';

export default function TermsConditions() {
    const parallax0 = useParallax({
        translateX: [-200, 20, "easeOutQuint"],
        translateY: [0, 0, "easeInQuint"],
    });
  return (
    <>
        <section className='tc-bnr'>
            <Container> 
                <Row> 
                    <Col lg={5} md={12} className='tc-bnr-right-col' data-aos="fade-right" data-aos-duration="500">
                        <img src={TcBnrImg} alt="Tow To Tango" ref={parallax0.ref} />
                    </Col> 
                    <Col lg={7} md={12} className='tc-bnr-left-col'>
                        <h2 data-aos="zoom-in" data-aos-duration="500">Terms And Conditions</h2> 
                    </Col>
                </Row>
            </Container> 
            <BookConsultation/> 
        </section>
        <section className='tc-content'>
            <Container>
                <Row>
                    <Col lg={12} md={12} className='policy-content-col'>
                        <div className='policy-content-col-inner'>
                            <p data-aos="fade-up" data-aos-duration="500">Two 2 Tango (www.two2tango.com) henceforth referred to as “TTT” or “Site,” provides highly confidential and personalised offline dating services.</p>
                            <p data-aos="fade-up" data-aos-duration="500">The Brand Two 2 Tango and the said site is owned and operated by ARMEIS INC., a company incorporated under the laws of the State of California and having its office at 1330 Avenue of the Americas, Suite 23A, New York, NY 10019. ARMEIS INC is a wholly owned subsidiary of ARMEIS ADVISORY PRIVATE LIMITED, a company incorporated under the Laws of India with its office at Level 3B, DLF Centre, Sansad Marg, Connaught Place, New Delhi-110001. For any query, concern or grievance in relation to the instant agreement, please free to contact us at email ID: <a href='mailto:contactus@two2tango.com'>contactus@two2tango.com</a>.</p>
                            <p data-aos="fade-up" data-aos-duration="1000">By using the site, you agree to the Terms and Conditions without any exceptions. TTT may change the terms and conditions at any time at its sole discretion. TTT may offer additional services or revise any of its services at its discretion, and this agreement will apply to all additional services or revised Services. TTT also reserves the right to cease offering any of its services. Your continued use of the service constitutes your acceptance of the Terms and Conditions and any revisions. The date of any revisions will be posted at the end of this agreement.</p>
                            <p data-aos="fade-up" data-aos-duration="1000">This agreement, accepted upon use of the site and further affirmed by becoming a member of the TTT service, contains the entire agreement between you and TTT regarding the use of the site and/or the service. If any provision of this agreement is held invalid, the remainder of this agreement shall continue in full force and effect.</p>
                            <p data-aos="fade-up" data-aos-duration="1000">By becoming a member of TTT and/or by entering the said site or using the services of the site, you unconditionally and irrevocably confirm that you have read and understood and that you agree to all the terms and conditions set forth hereinbelow and agree to abide by them in both letter and spirit.</p>
                            <h3 data-aos="fade-up" data-aos-duration="1500"><span>Eligibility</span></h3> 
                            <p data-aos="fade-up" data-aos-duration="1500">To register for the service with TTT, you must bean individual/ natural person (i.e., not anybody corporate, partnership or other business entity) at least 18 years of age and of sound mind;</p>
                            <p data-aos="fade-up" data-aos-duration="2000">By signing up and submitting your details, you implicitly confirm that you are disclosing accurate information and not misrepresenting or concealing any facts. This includes, but is not limited to, information about your age, marital status, employment, citizenship, work rights, etc. It is clarified that once you provide any information to TTT, you are also agreeing to TTT using it in any manner or form that TTT deems fit and proper for the rendering of services.</p>
                            <p data-aos="fade-up" data-aos-duration="2000">By creating an account, you grant to TTT a worldwide, perpetual, transferable, sub-licensable, royalty-free right and license to host, store, use, copy, display, reproduce, adapt, edit, publish, translate, modify, reformat, incorporate into other works, advertise, distribute and otherwise make available to the general public your content, including any information you authorize us to access from third-party sources (if applicable), in whole or in part, and in any way and in any format or medium currently known or developed in the future.</p>
                            <p data-aos="fade-up" data-aos-duration="2500">By registering for TTT services, you confirm that you have the right and legal capacity to enter into this agreement and that you are not violating any legal or personal laws or obligations.</p>
                            <p data-aos="fade-up" data-aos-duration="2500">If you are representing someone else and subscribing to this service on their behalf, you, are confirming, without reservation, that you have the legal right and permission from the person on whose behalf or for whom you are registering.</p>
                            <p data-aos="fade-up" data-aos-duration="2500">If TTT becomes aware that you have violated any of the above conditions, or have misrepresented any facts, TTT reserves the right to terminate your membership with immediate effect. In such a case, your membership fees will be forfeited in its entirety and TTT may also decide to proceed with legal proceedings against you in light of your acts/omissions to protect TTT’s reputation, rights or interests.</p>  
                            <h3 data-aos="fade-up" data-aos-duration="3000"><span>Conditions of Use </span></h3>
                            <p data-aos="fade-up" data-aos-duration="3000">By using TTT’s services and providing information to TTT, you agree to assume all liability regarding the authenticity, legality and accuracy of all the information provided by you or anyone who is creating your profile on the site. TTT does not conduct any kind of investigation or check, including but not limited to a person's background, legal or credit status, or criminal history. It is your sole responsibility todeclare the facts accurately without misleading or concealing any information. If someone else opens an account for you, it is assumed that the person has the required permission from you and is acting on your behalf with your consent and that such information is not incorrect or misleading or in any manner impinges upon any third party’s rights/interests. While TTT will do its best to find a suitable match for you as per your preference, it does not make any guarantees, either expressly or implied regarding the number of matches, the frequency of the matches, the success of the matches or the immediate or ultimate compatibility with individuals that you meet through its service.</p>
                            <p data-aos="fade-up" data-aos-duration="3500">TTT is not responsible for the conduct of the people you meet during the search, nor for how you react to a match meeting set up for you, as the final decision of the meeting is made by you. There are risks associated with meeting or communicating with people both offline and online, and you understand and assume all risks involved when entering this service and meeting persons though the service offered by TTT. The responsibility of taking all necessary or reasonable precautions to safeguard yourself against personal and professional harm is wholly and solely yours.</p>
                            <p data-aos="fade-up" data-aos-duration="3500">As TTT provides a personalised search service, you will be contacted from time to time either via email, phone, or other means or channels of communication so as to enable TTT to carry out its services effectively. During your membership tenure, you do not have the option to opt out of receiving this communication, as TTT will not be able to continue to provide this service to you without the required communication channels. You agree that this is necessary and acceptable to broaden the reach and find the right match for you. TTT has the right to remove any content that you have submitted if it is found to be objectionable. You agree that your account is for your personal use and is non-transferable. You are also solely responsible for protecting your account from misuse.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">You will not use the services provided by TTT for anything illegal under the laws of any country or to cause any harm to anyone else either directly or indirectly, without any exception. Furthermore, the TTT platform cannot be used for any commercial solicitation. Any illegal or unauthorised use of TTT, including unauthorised framing will be investigated and appropriate legal action will be taken, without limitation. You will inform TTT of any change in your life, be it personal or professional, which might have a direct or indirect bearing on your ability and eligibility with regard to the services that TTT offers. TTT has a very strict, zero-tolerance policy for any sort of harassment of its employees or any appointed third-party agents providing a service to you.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">You will not use TTT to advertise or solicit any products or services.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">You give TTT the complete right, without any exclusion, to use the information you have put on your profile or shared either during a conversation or meeting in person, as TTT deems fit, either to promote the TTT brand and services or to actively look for potential candidates.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">Violation of any of the terms of use may result in TTT pursuing any or all of the legal actions available, including but not limited to civil, criminal and injunctive redress.</p>   
                            <h3 data-aos="fade-up" data-aos-duration="3000"><span>Prohibited Content</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">TTT prohibits uploading or sharing content that:</p> 
                            <ol>
                                <li data-aos="fade-up" data-aos-duration="4500">Could reasonably be deemed to be offensive or to harass, upset, embarrass, alarm or annoy any other person;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Is obscene, pornographic, violent or otherwise may offend human dignity, or contains nudity;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Is abusive, insulting or threatening, discriminatory or that promotes or encourages racism, sexism, hatred or bigotry;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Encourages or facilitates any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which in itself constitutes committing a criminal offense;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Encourages or facilitates any activity that may result in harm to the user or another person, including, but not limited to, promotion of self-harm, eating disorders, dangerous challenges, violent extremism,</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Is defamatory, libelous, or untrue;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Involves the transmission of "junk" mail or "spam";</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Infringes upon any third party's rights (including, without limitation, intellectual property rights and privacy rights);</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Was not written by you or was automatically generated, unless expressly authorized by TTT;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Includes the image or likeness of another person without that person's consent (or in the case of a minor, the minor's parent or guardian), or is an image or likeness of a minor unaccompanied by the minor's parent or guardian—for the avoidance of doubt, TTT does not allow any minors in photos or live video, and any photo including a minor will be removed and any live video will be terminated;</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Is inconsistent with the intended use of the Services; or </li>   
                                <li data-aos="fade-up" data-aos-duration="4500">May harm the reputation of TTT or its affiliates, meaning the uploading or sharing of content on the TTT platform that is defamatory to TTT or its affiliates or advocates misuse of the Service or any service provided by TTT's affiliates.</li>   
                                <li data-aos="fade-up" data-aos-duration="4500">Any content which may be in conflict with the laws of any country.</li>     
                            </ol>  
                            <p data-aos="fade-up" data-aos-duration="4500">The uploading or sharing of content that violates the aforesaid terms ("Prohibited Content") may result in the immediate suspension or termination of your account.</p>  
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Termination</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">You may terminate your membership at any time for any reason by writing to TTT at contactus@two2tango.com. If you choose to terminate your membership, you will not be entitled to any refund of any unused/unutilised subscription fees paid by you. Likewise, TTT may terminate your access to the site and/or your membership if you are found in breach of the terms and conditions agreement or the privacy policy. This termination will be effective immediately upon sending a notice to you at the email address as provided by you. If TTT terminates your membership for breach of terms of this agreement, you will not be entitled to any refund of any unused/unutilised subscription fees and once terminated, you will not have any claim against TTT.</p>  
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Refund Policy</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">No refunds will be provided at any stage or under any circumstances.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Proprietary Rights</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">The content that you submit to TTT becomes the property of TTT. TTT has the unconditional right to use any part, or all of the information submitted on the TTT site or shared with its members and third-party agents, who provide services to enhance the experience of its members.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">All the information on TTT, including but not limited to copyrighted material, trademark or other proprietary/confidential information, is owned by TTT and any attempt to copy, modify, use, sell, distribute, or publish this will be considered a strict violation of this agreement and will result in immediate termination of membership as well as appropriate legal action. All content on TTT, including but not limited to text, design, concept, images, and logos is the property of TTT and is protected by applicable laws.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Membership Access/Rights</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">TTT has the right to refuse you membership without providing a reason, as TTT has certain eligibility criteria that must be met based on the information you provide.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">Your membership is valid for the duration of your subscription package, unless terminated earlier either by yourself or TTT. In either case, no refund will be provided for any unused/unutilised duration of membership. Once your membership expires or is terminated, you will be unable to access any of your shared or saved information.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">TTT reserves the right, but has no obligation, to monitor disputes between you and other members. TTT also reserves the right to take appropriate action against errant members. However, TTT is not obliged to share details of such actions with other members, including complainants.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Indemnity</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4500">You agree to indemnify, defend and hold harmlessARMEIS INC., ARMEIS ADVISORY PRIVATE LIMITED, TTT site and services, employees, directors, agents and all others acting for and on behalf of TTT and all related third parties from and against any and all losses, liabilities, claims, damages, costs and expenses, including reasonable legal fees and disbursements against or incurred by TTT resulting out of any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed or arising out of any third party claim that (a) your use of or inability to use the site or services, (b) any user postings made by you, (c) your violation of any terms of this agreement or your violation of any rights of a third party, or (d) your violation of any applicable laws, rules or regulations.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">You hereby specifically agree and confirm that you hereby indemnify ARMEIS INC. and ARMEIS ADVISORY PRIVATE LIMITED andTTT from any claim whatsoever that may be made against them by yourself or by any third party in relation to the uploading or use of any information or photograph or material that may be provided by you to TTT. You further confirm that any content or information or photograph or material that you may provide to TTT will be free from all claims and encumbrances whatsoever. </p> 
                            <p data-aos="fade-up" data-aos-duration="4500">You shall be solely and exclusively liable for any breach of any country-specific rules and regulations or general code of conduct and TTT cannot be held responsible for the same.</p>
                            <p data-aos="fade-up" data-aos-duration="4500">TTT reserves the right, at its own cost, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with TTT in asserting any available defences.</p>
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Disclaimer</span></h3> 
                            <p data-aos="fade-up" data-aos-duration="4500">You are solely responsible for the content you post on TTT and also for the safety and security of your account.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">TTT is not in any way responsible for any damage caused; personal, professional, emotional, or physical, as a result of you using the site or in your communications either online or offline with other matches.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">TTT liability is limited only to the extent of the membership fees that have been paid by the member.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">TTT assumes no responsibility for any content that you or another user or third party posts, sends, receives, and/or acts on through our services, nor does TTT assume any responsibility for the identity, intentions, legitimacy, or veracity of any users with whom you may communicate with through TTT. Any material downloaded or otherwise obtained through the use of our services is accessed at your own discretion and risk. TTT is not responsible for any damage to your computer hardware, computer software, or other equipment or technology including, but without limitation, damage from any security breach or from any virus, bugs, tampering, hacking, fraud, error, omission, interruption, defect, delay in operation or transmission, computer line or network failure, or any other technical or other disruption or malfunction.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Limitation On Liability</span></h3> 
                            <p data-aos="fade-up" data-aos-duration="4500">To the maximum extent permitted by applicable law, in no event will:</p>
                            <ol type='a'>
                                <li>TTT be liable for any incidental, special, consequential, or indirect damages arising out of or relating to the use or inability to use the services, including, without limitation, damages for loss or corruption of data or programs, service interruptions and procurement of substitute services, even if TTT knows or has been advised of the possibility of such damages. To the maximum extent permitted by applicable law, under no circumstances will TTT's aggregate liability, in any form of action whatsoever in connection with this agreement or the use of the services or the site exceed the price paid by you for your service.</li>
                                <li>TTT be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the services, including without limitation, bodily injury, emotional distress, and/or any other damages resulting from communications or meetings with other registered users of the services. This includes any claims, losses or damages arising from the conduct of users who have registered under false pretences or who attempt to defraud or harm you.</li>
                                <li>TTT be held responsible for information provided by a member. TTT does not verify any information that is provided by you and cannot be held responsible for any inaccurate or false information provided by a member. As it does not control the content posted via the service and, as such, does not guarantee the accuracy, integrity, or quality of such content. Under no circumstances will TTT be liable in any way for any content, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available via the service.</li>
                            </ol>
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Jurisdiction</span></h3>  
                            <p data-aos="fade-up" data-aos-duration="4500">Any disputes arising between users/subscribers/clients of  TTT and TTT will be resolved through arbitration. In the event of any dispute involving TTT and its services including any dispute arising from this agreement, all users/subscribers/clients of TTT and its services from India hereby unconditionally accept that all such disputes will be governed and decided by the laws of India and further such users/subscribers/clients agree to submit to the jurisdiction of the Courts in New Delhi, India. On the other hand, in case there is any dispute involving TTT and its services including any dispute arising from this agreement, all users/subscribers/clients of TTT and its services from anywhere outside India hereby unconditionally accept that all such disputes will be governed and decided by the laws of the State of California and the Courts in the State of California will have sole and exclusive jurisdiction over such disputes and claims.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">Regardless of any statute or law to the contrary, and to the maximum extent permitted by applicable law, any dispute must be filed within 3 months of the date on which the incident giving rise to the dispute occurred. Failure to file an arbitration claim, within the applicable time limit constitutes an absolute and unconditional waiver of such a claim.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Sharing Of Information & Third Parties</span></h3>  
                            <p data-aos="fade-up" data-aos-duration="4500">TTT maintains a presence on social media and occasionally promotes its services there by actively engaging with you. If you choose to connect your TTT account to any social network, you stand the risk of making your information public. It is important that you thoroughly understand the Terms and Conditions of the social media site before you make a decision, as TTT is not responsible for any action taken by the third parties.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">The TTT Site may contain links to other third-party websites that are not under TTT’s control. TTT is not responsible for the content of any linked site or any link contained in a linked site, or any changes or updates to such websites. TTT provides these links for your convenience, and the inclusion of any link does not imply that TTT endorses or accepts any responsibility for the content on such third-party web sites.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Privacy Policy</span></h3>  
                            <p data-aos="fade-up" data-aos-duration="4500">Please carefully read the TTT Privacy Policy before you accept the Terms and Conditions agreement. By accepting, you implicitly confirm that you have read the policy and agree to abide by it without any exceptions.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4500"><span>Acceptance Policy & Changes</span></h3>  
                            <p data-aos="fade-up" data-aos-duration="4500">Acceptance Policy: By using this website, you agree to the terms of these Terms & Conditions. Any use of the website implies the acceptance of the entire Terms and Conditions and Privacy Policy.</p> 
                            <p data-aos="fade-up" data-aos-duration="4500">Changes: TTT reserves the right to amend the Terms and Conditions at any time. Any changes made will be posted in this statement. Your continued use of any portion of our website following posting of the updated Terms and Conditions will constitute your acceptance of the changes. Please make sure you check our Terms & Conditions and Privacy Policy before confirming anything.</p>  
                             
                            {/* <div className='terms-condition-load-more'>
                                <Button className='load-more'>LOAD MORE</Button>
                            </div> */}
                            <h4>Last change made: 21<sup>st</sup> June 2024</h4>
                         </div>
                    </Col> 
                </Row>
            </Container> 
        </section>
    </>
  )
}
