import React, { useEffect, useState } from "react";
import "./NewMatches.css";

import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AddFeedback, GetMyMatches } from "../../apis/MyMatchesAPI";
import { GetBucketUrl } from "../../utils/Utility";
import { useSelector } from "react-redux";

export default function NewMatches() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const options = {
    margin: 30,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      575: {
        items: 1,
        nav: true,
      },
      767: {
        items: 2,
        nav: true,
      },
      992: {
        items: 3,
        nav: false,
      },
    },
  };

  const [myMatches, setMyMatches] = useState({
    newMatches: [],
    myInterests: [],
    interestedInMe: [],
    mutualInterest: [],
    declinedMatches: [],
  });

  useEffect(() => {
    GetMatches();
  }, []);

  async function GetMatches() {
    var result = await GetMyMatches();
    let newMatches = result.filter((x) => x.matchType === 0);
    let myInterests = result.filter((x) => x.matchType === 1);
    let interestedInMe = result.filter((x) => x.matchType === 2);
    let mutualInterest = result.filter((x) => x.matchType === 3);
    let declinedMatches = result.filter((x) => x.matchType === 4);

    setMyMatches({
      newMatches: newMatches,
      myInterests: myInterests,
      interestedInMe: interestedInMe,
      mutualInterest: mutualInterest,
      declinedMatches: declinedMatches,
    });
  }

  useEffect(() => {
    console.log(myMatches);
  }, [myMatches]);

  const handleViewProfile = (uid, matchtype) => {
    navigate(`/MyProfile?userid=${uid}&type=${matchtype}`);
  };

  const handleRekindleInterest = async (userId) => {
    var data = {
      matchUserId: userId,
      hisResponse: user.userGender === "male" ? "Yes" : "",
      herResponse: user.userGender === "female" ? "Yes" : "",
      feedback: "",
    };

    await AddFeedback(data);
    window.location.reload();
  };

  return (
    <>
      <section className="my-matches-page">
        <Container fluid>
          <Row>
            <Col className="p-0">
              <Tabs
                defaultActiveKey="new-matches"
                id="my-matches"
                className="my-matches-tabs"
              >
                <Tab eventKey="new-matches" title="New Matches">
                  <Container>
                    <div className="tabs-title-tab">
                      <h4>New Matches</h4>
                    </div>
                    {myMatches.newMatches.length > 0 ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={myMatches.newMatches.length >= 3 ? true : false}
                        margin={10}
                        nav
                        dots
                        {...options}
                      >
                        {myMatches.newMatches.map((item, indx) => (
                          <div
                            className="item"
                            key={`nm_${indx}`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleViewProfile(item.encryptUserId, "newmatch")
                            }
                          >
                            <div className="new-matches-inner-col">
                              <div
                                className="new-matches-img"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                {item.userImage && item.userImage != "" ? (
                                  <img
                                    src={`${GetBucketUrl()}${item.id}/${
                                      item.userImage
                                    }?modifed=${item.imageModified}`}
                                    alt="TTT"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.userGender === "male"
                                        ? `${GetBucketUrl()}default_man.png`
                                        : `${GetBucketUrl()}default_woman.png`
                                    }
                                    alt="TTT"
                                  />
                                )}
                              </div>
                              <div className="new-matches-info">
                                <h4
                                  className="matches-name"
                                  data-aos="fade-up"
                                  data-aos-duration="500"
                                >
                                  {item.userName}
                                </h4>
                                <p
                                  className="matches-location"
                                  data-aos="fade-up"
                                  data-aos-duration="1000"
                                >
                                  <span>
                                    {`${item.userCity}`}
                                    {item.userCity && ", "}
                                    {item.userCountry}
                                  </span>
                                </p>
                                {/* <p
                                  className="last-contacted"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  Last contacted on: 22/02/2024
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    ) : (
                      ""
                    )}
                  </Container>
                </Tab>
                <Tab eventKey="my-interest" title="My Interests">
                  <Container>
                    <div className="tabs-title-tab">
                      <h4>My Interests</h4>
                    </div>
                    {myMatches.myInterests.length > 0 ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={myMatches.myInterests.length >= 3 ? true : false}
                        margin={10}
                        nav
                        dots
                        {...options}
                      >
                        {myMatches.myInterests.map((item, indx) => (
                          <div
                            className="item"
                            key={`nm_${indx}`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleViewProfile(
                                item.encryptUserId,
                                "myinterest"
                              )
                            }
                          >
                            <div className="new-matches-inner-col">
                              <div
                                className="new-matches-img"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                {item.userImage && item.userImage != "" ? (
                                  <img
                                    src={`${GetBucketUrl()}${item.id}/${
                                      item.userImage
                                    }?modifed=${item.imageModified}`}
                                    alt="TTT"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.userGender === "male"
                                        ? `${GetBucketUrl()}default_man.png`
                                        : `${GetBucketUrl()}default_woman.png`
                                    }
                                    alt="TTT"
                                  />
                                )}
                              </div>
                              <div className="new-matches-info">
                                <h4
                                  className="matches-name"
                                  data-aos="fade-up"
                                  data-aos-duration="500"
                                >
                                  {item.userName}
                                </h4>
                                <p
                                  className="matches-location"
                                  data-aos="fade-up"
                                  data-aos-duration="1000"
                                >
                                  <span>
                                    {`${item.userCity}`}
                                    {item.userCity && ", "}
                                    {item.userCountry}
                                  </span>
                                </p>
                                {/* <p
                                  className="last-contacted"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  Last contacted on: 22/02/2024
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      </OwlCarousel>
                    ) : (
                      ""
                    )}
                  </Container>
                </Tab>
                <Tab eventKey="interest-me" title="Interested In Me">
                  <Container>
                    <div className="tabs-title-tab">
                      <h4>Interested In Me</h4>
                    </div>
                    {myMatches.interestedInMe.length > 0 ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={
                          myMatches.interestedInMe.length >= 3 ? true : false
                        }
                        margin={10}
                        nav
                        dots
                        {...options}
                      >
                        {myMatches.interestedInMe.map((item, indx) => (
                          <div
                            className="item"
                            key={`nm_${indx}`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleViewProfile(
                                item.encryptUserId,
                                "interestedme"
                              )
                            }
                          >
                            <div className="new-matches-inner-col">
                              <div
                                className="new-matches-img"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                {item.userImage && item.userImage != "" ? (
                                  <img
                                    src={`${GetBucketUrl()}${item.id}/${
                                      item.userImage
                                    }?modifed=${item.imageModified}`}
                                    alt="TTT"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.userGender === "male"
                                        ? `${GetBucketUrl()}default_man.png`
                                        : `${GetBucketUrl()}default_woman.png`
                                    }
                                    alt="TTT"
                                  />
                                )}
                              </div>
                              <div className="new-matches-info">
                                <h4
                                  className="matches-name"
                                  data-aos="fade-up"
                                  data-aos-duration="500"
                                >
                                  {item.userName}
                                </h4>
                                <p
                                  className="matches-location"
                                  data-aos="fade-up"
                                  data-aos-duration="1000"
                                >
                                  <span>
                                    {`${item.userCity}`}
                                    {item.userCity && ", "}
                                    {item.userCountry}
                                  </span>
                                </p>
                                {/* <p
                                  className="last-contacted"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  Last contacted on: 22/02/2024
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      </OwlCarousel>
                    ) : (
                      ""
                    )}
                  </Container>
                </Tab>
                <Tab eventKey="mutual-interest" title="Mutual Interest">
                  <Container>
                    <div className="tabs-title-tab">
                      <h4>Mutual Interest</h4>
                    </div>
                    {myMatches.mutualInterest.length > 0 ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={
                          myMatches.mutualInterest.length >= 3 ? true : false
                        }
                        margin={10}
                        nav
                        dots
                        {...options}
                      >
                        {myMatches.mutualInterest.map((item, indx) => (
                          <div
                            className="item"
                            key={`nm_${indx}`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleViewProfile(item.encryptUserId, "mutual")
                            }
                          >
                            <div className="new-matches-inner-col">
                              <div
                                className="new-matches-img"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                {item.userImage && item.userImage != "" ? (
                                  <img
                                    src={`${GetBucketUrl()}${item.id}/${
                                      item.userImage
                                    }?modifed=${item.imageModified}`}
                                    alt="TTT"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.userGender === "male"
                                        ? `${GetBucketUrl()}default_man.png`
                                        : `${GetBucketUrl()}default_woman.png`
                                    }
                                    alt="TTT"
                                  />
                                )}
                              </div>
                              <div className="new-matches-info">
                                <h4
                                  className="matches-name"
                                  data-aos="fade-up"
                                  data-aos-duration="500"
                                >
                                  {item.userName}
                                </h4>
                                <p
                                  className="matches-location"
                                  data-aos="fade-up"
                                  data-aos-duration="1000"
                                >
                                  <span>
                                    {`${item.userCity}`}
                                    {item.userCity && ", "}
                                    {item.userCountry}
                                  </span>
                                </p>
                                {/* <p
                                  className="last-contacted"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  Last contacted on: 22/02/2024
                                </p> */}
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      </OwlCarousel>
                    ) : (
                      ""
                    )}
                  </Container>
                </Tab>
                <Tab eventKey="declined-interest" title="Declined Interest">
                  <Container>
                    <div className="tabs-title-tab">
                      <h4>Declined Interest</h4>
                    </div>
                    {myMatches.declinedMatches.length > 0 ? (
                      <OwlCarousel
                        className="owl-theme"
                        loop={
                          myMatches.declinedMatches.length >= 3 ? true : false
                        }
                        margin={10}
                        nav
                        dots
                        {...options}
                      >
                        {myMatches.declinedMatches.map((item, indx) => (
                          <div className="item" key={`nm_${indx}`}>
                            <div className="new-matches-inner-col">
                              <div
                                className="new-matches-img"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleViewProfile(
                                    item.encryptUserId,
                                    "declined"
                                  )
                                }
                              >
                                {item.userImage && item.userImage != "" ? (
                                  <img
                                    src={`${GetBucketUrl()}${item.id}/${
                                      item.userImage
                                    }?modifed=${item.imageModified}`}
                                    alt="TTT"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.userGender === "male"
                                        ? `${GetBucketUrl()}default_man.png`
                                        : `${GetBucketUrl()}default_woman.png`
                                    }
                                    alt="TTT"
                                  />
                                )}
                              </div>
                              <div className="new-matches-info">
                                <h4
                                  className="matches-name"
                                  data-aos="fade-up"
                                  data-aos-duration="500"
                                >
                                  {item.userName}
                                </h4>
                                <p
                                  className="matches-location"
                                  data-aos="fade-up"
                                  data-aos-duration="1000"
                                >
                                  <span>
                                    {`${item.userCity}`}
                                    {item.userCity && ", "}
                                    {item.userCountry}
                                  </span>
                                </p>
                                {/* <p
                                  className="last-contacted"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  Last contacted on: 22/02/2024
                                </p> */}
                                <p
                                  className="matches-btn"
                                  data-aos="fade-up"
                                  data-aos-duration="1500"
                                >
                                  <Link
                                    href="#"
                                    onClick={() =>
                                      handleRekindleInterest(item.encryptUserId)
                                    }
                                  >
                                    Rekindle Interest
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      </OwlCarousel>
                    ) : (
                      ""
                    )}
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
