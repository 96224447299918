import React from 'react'
import './HomeLanding.css'; 
import { Col, Container, Row } from 'react-bootstrap';
import HomeLandingBnr from "../../images/home-landing-bnr.jpg";
import NewMatchesImg1 from "../../images/new-matches1.jpg";
import NewMatchesImg2 from "../../images/new-matches2.jpg";
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function HomeLanding() {
    const options = {
        loop:true,
        margin:30,
        dots: false,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            575:{
                items:1,
                nav:true
            },
            767:{
                items:2,
                nav:true
            },
            992:{
                items:1,
                nav:false
            },
            1199:{
                items:2,
                nav:false
            },
            1400:{
                items:2,
                nav:false
            }
        }
    } 
  return (
    <>
        <section className='home-landing-bnr'>
            <Container fluid>
                <Row>
                    <Col lg={6} className='p-0'>
                        <div className='home-landing-bnr-left' data-aos="fade-right" data-aos-duration="1000">
                            <img src={HomeLandingBnr} alt="TTT" />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='home-landing-bnr-right'>
                            <div className='home-landing-bnr-right-inner'>
                                <h3 data-aos="fade-up" data-aos-duration="1000">Every heart has a <span>story</span>, every soul a <span>destination</span>.</h3>
                                <p data-aos="fade-up" data-aos-duration="1500">In our diverse community, no story is too unique, no experience</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='home-landing-matches-notifications'>
            <Container>
                <Row>
                    <Col lg={5} md={12}>
                        <div className='home-new-matches'>
                            <h2 className='healine' data-aos="zoom-in" data-aos-duration="1000"><span>New Matches</span></h2>
                            <div className='new-matches-inner'>
                                <OwlCarousel className='owl-theme' loop margin={10} nav dots {...options}>
                                    <div class='item'>
                                        <div className='new-matches-inner-col'>
                                            <div className='new-matches-img' data-aos="zoom-in" data-aos-duration="1000">
                                                <img src={NewMatchesImg1} alt="TTT" />
                                            </div>
                                            <div className='new-matches-info'>
                                                <h4 className='matches-name' data-aos="fade-up" data-aos-duration="1000">Angeline Mathews</h4>
                                                <p className='matches-location' data-aos="fade-up" data-aos-duration="1500"><span>Bengaluru, Karnataka</span></p>
                                                <p className='last-contacted' data-aos="fade-up" data-aos-duration="1500">Last contacted on: 29/12/2023</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='item'>
                                        <div className='new-matches-inner-col'>
                                            <div className='new-matches-img' data-aos="zoom-in" data-aos-duration="1000">
                                                <img src={NewMatchesImg2} alt="TTT" />
                                            </div>
                                            <div className='new-matches-info'>
                                                <h4 className='matches-name' data-aos="fade-up" data-aos-duration="1000">Sanjana Mehta</h4>
                                                <p className='matches-location' data-aos="fade-up" data-aos-duration="1500"><span>Pune, Maharashtra</span></p>
                                                <p className='last-contacted' data-aos="fade-up" data-aos-duration="1500">Last contacted on: 08/02/2024</p>
                                            </div>
                                        </div>
                                    </div> 
                                </OwlCarousel>  
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} md={12}>
                        <div className='home-notifications'>
                            <div className='home-notifications-inner'>
                                <h2 className='healine' data-aos="zoom-in" data-aos-duration="1000"><span>Notifications</span></h2>
                                <div className='notifications-list'>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="1000">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg1} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            You have been matched with <strong>Mansi Sharma</strong>
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            February 21st, 2024
                                        </div>
                                    </div>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg2} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            There are people with similar interests as yours. Tap to see.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            February 7th, 2024
                                        </div>
                                    </div>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="2000">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg1} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            <strong>Alia Kapoor</strong> updated her profile picture.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            January 15th, 2024
                                        </div>
                                    </div>
                                    <div className='notifications-list-item' data-aos="fade-up" data-aos-duration="2500">
                                        <div className='notifications-list-item-img'>
                                            <img src={NewMatchesImg2} alt="TTT" />
                                        </div>
                                        <div className='notifications-list-item-info'>
                                            <strong>Ananya Srivastav</strong> updated her bio.
                                        </div>
                                        <div className='notifications-list-item-date'>
                                            January 13th, 2024
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
