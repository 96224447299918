import { Watch } from "react-loader-spinner";
import "./Loader.css";

export default function Loader(props) {
  return (
    <Watch
      visible={props.visible}
      height="25"
      width="25"
      colors="#000000"
      ariaLabel="watch-loading"
      wrapperStyle={{
        paddingLeft: "10px",
      }}
      wrapperClass=""
    />
  );
}
