import React, { useEffect } from "react";
import "./Home.css";
import { Col, Container, Row } from "react-bootstrap";
import HomeBnr from "../../images/home-bnr-vector1.png";
import BnrVector from "../../images/yellow-flowers-vector.png";
import Curated3 from "../../images/fold-2-bg3.jpg";
import BelongHereImg from "../../images/home-fold-3-left-img.jpg";
import HomeBoatImg from "../../images/boat-vector.png";
import { useParallax } from "react-scroll-parallax";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";

export default function HomePage() {
  
  const parallax0 = useParallax({
    translateX: [0, 0, "easeOutQuint"],
    translateY: [20, -100, "easeInQuint"],
  });
  const parallax = useParallax({
    translateX: [-240, 13, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  const parallax2 = useParallax({
    translateX: [0, 0, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  const parallax3 = useParallax({
    translateX: [-80, 0, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  const parallaxRotate = useParallax({
    rotate: [0, 0],
  });

  return (
    <>
      <section className="home-bnr">
        <Container>
          <Row>
            <Col
              lg={6}
              md={12}
              className="home-bnr-left-col"
              ref={parallax0.ref}
            >
              <h1 data-aos="fade-up">
                Beyond Algorithms, <br />
                Beyond Apps:
              </h1>
              <h4 data-aos="fade-up">
                <span>Craft Your Love Story.</span>
              </h4>
              <p data-aos="fade-up">
                Rediscover the magic of romance and companionship in the
                fabulous world of Two 2 Tango. Orchestrate your love story
                across some of the most vibrant cities in the world - New York,
                San Francisco, Dubai, London, Mumbai, New Delhi, Singapore, and
                Hong Kong.
              </p>
            </Col>
            <Col
              lg={6}
              md={12}
              className="home-bnr-right-col"
              data-aos="fade-up"
            >
              <img src={HomeBnr} alt="Tow To Tango Banner" ref={parallax.ref} />
            </Col>
          </Row>
        </Container>
        <div className="banner-vector" ref={parallax2.ref}>
          <img src={BnrVector} alt="Tow To Tango Banner" />
        </div>
        <BookConsultation />
      </section>
      <section className="curated-connections">
        <Container>
          <Row>
            <Col>
              <div className="section-head" data-aos="zoom-in-up">
                {/* <h2>Curated Connections Beyond Borders.</h2> */}
                <h2>Authenticity. Intent. Quality. Curation. Love.</h2>
                <h4>
                  <span></span>
                </h4>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={3} md={6}>
              <div
                className="curated-col curated1"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <p>
                  Think of us as your love GPS, bypassing the dead ends and
                  detours of dating apps to find someone who cherishes the
                  authentic <i>you</i>.
                </p>
              </div>
            </Col>
            <Col>
              <div
                className="curated-col curated2"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <p>Forget awkward swiping and forced perfection. </p>
                <br />
                <p>
                  Here laughter, wisdom, and a dash of sass are not just
                  welcome, they're celebrated. So dust off your dancing shoes
                  and prepare to tango into meaningful connections that stand
                  the test of time.
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className="curated-col curated3"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <img
                  src={Curated3}
                  alt="Tow To Tango"
                  ref={parallaxRotate.ref}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="belong-here-section">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div
                className="belong-here-section-left"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img src={BelongHereImg} alt="Tow To Tango" />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="belong-here-section-right">
                <div className="belong-here-section-right-inner">
                  <div
                    className="belong-here-section-right-inner-items"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine"
                  >
                    <p>
                      You are meant to be here if you value emotional
                      intelligence, commitment, and desire to create a special
                      space with someone. One that belongs just to the two of
                      you.{" "}
                    </p>
                    {/* <h4>Don't just find a match, find your equal.</h4>  */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-boat" ref={parallax3.ref}>
          <img src={HomeBoatImg} alt="Tow To Tango" />
        </div>
      </section>
    </>
  );
}
