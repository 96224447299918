import React from "react";
import "./Membership.css";
import MembershipPackagesDancingCouple2 from "../../images/membership-packages-dancing-couple2.png";
import PlusMembership3colImg1 from "../../images/concierge-service-3col1.jpg";
import PlusMembership3colImg2 from "../../images/concierge-service-3col2.jpg";
import PlusMembership3colImg3 from "../../images/concierge-service-3col3.jpg";
import { Col, Container, ListGroup, Row } from "react-bootstrap";

import { useParallax } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";

export default function OneYearMembership(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const parallax3 = useParallax({
    translateX: [-50, 0, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
    disabled: isMobile,
  });

  return (
    <>
      <section className="membership-packages2">
        <Container>
          <Row>
            <Col lg={8} md={12} className="membership-packages-info">
              <div className="membership-packages-info-inner">
                <div
                  className="membership-packages-head"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <div className="membership-packages-name">
                    <span>Tango Plus</span>
                  </div>
                  <div className="membership-packages-price">
                    {props.countryCode === "IN" ? (
                      <span>
                        Rs. 75,000 <small>12 months</small>
                      </span>
                    ) : (
                      <span>
                        $1100 <small>12 months</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="membership-packages-choose-info">
                  <h6 data-aos="fade-up" data-aos-duration="500">
                  Waltz into love with our 1-year membership: 
                  </h6>
                  <p data-aos="fade-up" data-aos-duration="1000">
                  The Tango 12-month membership helps you discover love through carefully curated offline connections.
                  </p>
                </div>
                <div className="membership-packages-detail-info">
                  <div className="membership-packages-detail-info-head">
                    <h4 data-aos="zoom-in" data-aos-duration="500">
                      <span>What awaits you</span>
                    </h4>
                  </div>
                  <Row className="membership-packages-detail-3col-row">
                    <Col
                      lg={4}
                      md={4}
                      className="membership-packages-detail-3col"
                    >
                      <h5 data-aos="fade-up" data-aos-duration="500">
                        Crafted Matches:
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="1000">
                      Our experienced matchmakers personally select individuals who mirror your aspirations, values, and lifestyle. No algorithms, just real compatibility.
                      </p>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      className="membership-packages-detail-3col"
                    >
                      <h5 data-aos="fade-up" data-aos-duration="500">
                        Beyond Bios:
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="1000">
                      Enjoy confidential, in-person meetings with compatible individuals, and a chance to connect and explore chemistry with real-life interactions.
                      </p>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      className="membership-packages-detail-3col"
                    >
                      <h5 data-aos="fade-up" data-aos-duration="500">
                        Authenticity & Intent:
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="1000">
                      Our members are looking at forming a bond that is meaningful, deep and honest. 
                      </p>
                    </Col>
                  </Row>
                  <div className="deliverables-list">
                    <div className="deliverables-list-head">
                      <h4 data-aos="zoom-in" data-aos-duration="500">
                        <span>Deliverables You Can Tango With: </span>
                      </h4>
                    </div>
                    <ListGroup>
                      <ListGroup.Item
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        12 curated profiles. 
                      </ListGroup.Item>
                      <ListGroup.Item
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        Confidentiality and discretion throughout your experience.
                      </ListGroup.Item>
                      <ListGroup.Item
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        Active feedback on profiles shared.
                      </ListGroup.Item>
                      <ListGroup.Item
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        One relationship coaching session on communication, confidence and navigating the dating landscape.
                      </ListGroup.Item>
                      <ListGroup.Item
                        data-aos="fade-up"
                        data-aos-duration="2500"
                      >
                        Priority access to exclusive member events to expand your social circle.
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} className="membership-packages-img">
              <div className="membership-packages-img-inner">
                <img
                  src={MembershipPackagesDancingCouple2}
                  alt="Tow To Tango"
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  ref={parallax3.ref}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="concierge-service">
        <Container>
          <Row>
            <Col lg={12} md={12} className="concierge-service-top">
              <div className="concierge-service-inner">
                <div className="membership-packages-head cupid-head">
                  <div className="membership-packages-name">
                    <span>Cupids’ Concierge Service</span>
                  </div>
                  <div className="membership-packages-price">
                    {props.countryCode === "IN" ? (
                      <span>
                        Rs. 30,000 <small>12 months</small>
                      </span>
                    ) : (
                      <span>
                        $750 <small>12 months</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="membership-packages-choose-info">
                  <p data-aos="fade-up" data-aos-duration="1000">
                  Add our Cupid’s Concierge along with your chosen membership for an enhanced, elevated, and upgraded experience. 
                  </p>
                </div>

                <div className="plus-membership-packages-detail-info">
                  <Row className="plus-membership-packages-detail-3col-row">
                    <Col
                      lg={3}
                      md={6}
                      className="plus-membership-packages-detail-3col"
                    >
                      <div className="plus-membership-3col-inner">
                        <div className="plus-membership-3col-img">
                          <img
                            src={PlusMembership3colImg2}
                            alt="Two To Tango"
                          />
                        </div>
                        <div className="plus-membership-3col-text">
                          <h5 data-aos="fade-up" data-aos-duration="500">
                            Profile Brilliance:
                          </h5>
                          <p data-aos="fade-up" data-aos-duration="1000">
                          Create an irresistible profile that captures your essence and intrigues potential partners. We help you form an authentic and appealing first impression that showcases your best attributes and conveys what makes you unique. From selecting the right photos to an adequate bio, we help you put your best foot forward.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={6}
                      className="plus-membership-packages-detail-3col"
                    >
                      <div className="plus-membership-3col-inner">
                        <div className="plus-membership-3col-img">
                          <img
                            src={PlusMembership3colImg1}
                            alt="Tow To Tango"
                          />
                        </div>
                        <div className="plus-membership-3col-text">
                          <h5 data-aos="fade-up" data-aos-duration="500">
                            Pre-date Jitters? Conquer Them:
                          </h5>
                          <p data-aos="fade-up" data-aos-duration="1000">
                          Our relationship coaches empower you with confidence and communication tools as you navigate uncharted waters.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={6}
                      className="plus-membership-packages-detail-3col"
                    >
                      <div className="plus-membership-3col-img">
                        <img src={PlusMembership3colImg3} alt="Tow To Tango" />
                      </div>
                      <div className="plus-membership-3col-text">
                        <h5 data-aos="fade-up" data-aos-duration="500">
                          Feedback is the Key:
                        </h5>
                        <p data-aos="fade-up" data-aos-duration="1000">
                        Share your experiences, and we'll refine your journey based on your feedback.
                        </p>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      md={6}
                      className="plus-membership-packages-detail-3col"
                    >
                      <div className="plus-membership-3col-inner">
                        <div className="plus-membership-3col-img">
                          <img
                            src={PlusMembership3colImg3}
                            alt="Tow To Tango"
                          />
                        </div>
                        <div className="plus-membership-3col-text">
                          <h5 data-aos="fade-up" data-aos-duration="500">
                            Priority Access:
                          </h5>
                          <p data-aos="fade-up" data-aos-duration="1000">
                            Enjoy privileged entry to exclusive events and
                            gatherings, expanding your social circle and opening
                            doors to new encounters.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
