import React, { useEffect, useState } from "react";
import "./Login.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import LoginRightImg from "../../images/login-right-img.jpg";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import md5 from "md5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage";
import Loader from "../../Components/Spinner/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword, ChangePassword as cp } from "../../apis/UserAPI";
import { logout } from "../../slices/authSlice";

export default function ChangePassword() {
  const [pageTitle, setPageTitle] = useState("");
  const [searchParam] = useSearchParams();
  const [token, setToken] = useState(searchParam.get("token"));
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [changePasswordData, setChangePasswordData] = useState({
    userEmail: {
      value: "",
      show: false,
      invalid: false,
      invalidMsg: "Email required",
    },
    oldPassword: {
      value: "",
      show: false,
      invalid: false,
      invalidMsg: "Old password required",
    },
    newPassword: {
      value: "",
      show: true,
      invalid: false,
      invalidMsg: "New password required",
    },
    confirmPassword: {
      value: "",
      show: true,
      invalid: false,
      invalidMsg: "Confirm password required",
    },
  });

  const updateChangePasswordData = (
    key,
    value,
    isVisible,
    invalid,
    invalidMsg
  ) => {
    const emailRegex =
      /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/g;
    if (key === "userEmail" && !emailRegex.test(value) && value !== "") {
      invalid = true;
      invalidMsg = "Invalid email";
    }
    if (key === "newPassword" && !passwordRegex.test(value)) {
      invalid = true;
      invalidMsg =
        "Minimum eight and maximum ten characters, at least one uppercase letter, one lowercase letter, one number, and one special character";
    }
    if (
      key === "confirmPassword" &&
      changePasswordData.newPassword.value !== value
    ) {
      invalid = true;
      invalidMsg = "Password did not match";
    }
    setChangePasswordData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value,
        show: isVisible,
        invalid: invalid,
        invalidMsg: invalidMsg,
      },
    }));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      var t = token.replace(/\+/g, "%2B");
      setToken(t);
      updateChangePasswordData("userEmail", "", true, false, "Email required");
      document.getElementById("EmailAddress").setAttribute("required", "");
      updateChangePasswordData(
        "oldPassword",
        "",
        false,
        false,
        "Old password required"
      );
      document.getElementById("OldPassword").removeAttribute("required");
      setPageTitle("Reset Password");
    } else {
      updateChangePasswordData("userEmail", "", false, false, "Email required");
      document.getElementById("EmailAddress").removeAttribute("required");
      updateChangePasswordData(
        "oldPassword",
        "",
        true,
        false,
        "Old password required"
      );
      document.getElementById("OldPassword").setAttribute("required", "");
      setPageTitle("Change Password");
    }
  }, []);

  const [alertData, setAlertData] = useState({
    variant: "",
    message: "",
    showAlert: false,
    date: Date.now(),
  });

  const [showLoader, setShowLoader] = useState(false);

  const isFormValid = () => {
    for (const key in changePasswordData) {
      if (changePasswordData.hasOwnProperty(key)) {
        if (changePasswordData[key].invalid) return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false || !isFormValid()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (isAuthenticated) {
        setShowLoader(true);
        var result = await cp(
          md5(changePasswordData.oldPassword.value),
          md5(changePasswordData.newPassword.value)
        );
        if (result === "0") {
          setShowLoader(false);
          setAlertData({
            variant: "Dark",
            message: "Old password did not match",
            showAlert: true,
            date: Date.now(),
          });
        } else {
          setShowLoader(false);
          setAlertData({
            variant: "Success",
            message: "Password updated successfully",
            showAlert: true,
            date: Date.now(),
          });
          setTimeout(() => {
            dispatch(logout());
          }, 3000);
        }
      } else {
        setShowLoader(true);
        const data = {
          token: token,
          password: md5(changePasswordData.newPassword.value),
          userEmail: changePasswordData.userEmail.value,
        };
        var result = await ResetPassword(data);
        if (result === true || result === "true") {
          setShowLoader(false);
          setAlertData({
            variant: "Success",
            message: "Password reset successfully",
            showAlert: true,
            date: Date.now(),
          });
          setTimeout(() => {
            navigate("/Signin");
          }, 3000);
        } else {
          setShowLoader(false);
          setAlertData({
            variant: "Dark",
            message: "Reset password link is expired",
            showAlert: true,
            date: Date.now(),
          });
        }
      }
    }

    setValidated(true);
  };
  return (
    <>
      <section className="login">
        <AlertMessage
          variant={alertData.variant}
          message={alertData.message}
          show={alertData.showAlert}
          delay={3000}
          date={alertData.date}
        />
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-left signup-form">
                <h2 data-aos="zoom-in" data-aos-duration="1000">
                  {pageTitle}
                </h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="EmailAddress"
                      label="Email Address"
                      hidden={!changePasswordData.userEmail.show}
                    >
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email Address"
                        value={changePasswordData.userEmail.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "userEmail",
                            e.target.value,
                            true
                          )
                        }
                        isInvalid={changePasswordData.userEmail.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.userEmail.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="OldPassword"
                      label="Old Password"
                      hidden={!changePasswordData.oldPassword.show}
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Old Password"
                        value={changePasswordData.oldPassword.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "oldPassword",
                            e.target.value,
                            true
                          )
                        }
                        isInvalid={changePasswordData.oldPassword.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.oldPassword.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="NewPassword"
                      label="New Password"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="New Password"
                        value={changePasswordData.newPassword.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "newPassword",
                            e.target.value,
                            true
                          )
                        }
                        isInvalid={changePasswordData.newPassword.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.newPassword.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="ConfirmPassword"
                      label="Confirm Password"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm Password"
                        value={changePasswordData.confirmPassword.value}
                        onChange={(e) =>
                          updateChangePasswordData(
                            "confirmPassword",
                            e.target.value,
                            true
                          )
                        }
                        isInvalid={changePasswordData.confirmPassword.invalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {changePasswordData.confirmPassword.invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Row>
                  <Button
                    className="login-btn"
                    type="submit"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    UPDATE
                    <Loader visible={showLoader} />
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="login-right">
                <img src={LoginRightImg} alt="Tow To Tango" />
              </div>
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
    </>
  );
}
