import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Profile/";

const profileAPIService = new APIService(baseUrl);

export async function GetAboutMe(userid) {
  let url = `getaboutme?userId=${userid}`;
  return await profileAPIService.fetchData(url);
}

export async function SaveAboutMe(data) {
  let url = "saveaboutme";
  return await profileAPIService.postData(url, data);
}

export async function GetUserQnA(tab, userid) {
  let url = `getuserqna?tab=${tab}&userId=${userid}`;
  return await profileAPIService.fetchData(url);
}

export async function SaveQnA(data) {
  let url = "saveqna";
  return await profileAPIService.postData(url, data);
}

export async function GetUserSnapshot(uid) {
  let url = `getusersnapshot?userId=${uid}`;
  return await profileAPIService.fetchData(url);
}

export async function ContactUs(contactus) {
  let url = "contactus";
  return await profileAPIService.postData(url, contactus);
}

export async function GetMyMembershipInfo(){
  let url = "getmymembershipinfo";
  return await profileAPIService.fetchData(url);
}

export async function GetUserSectionStatus(){
  let url = "getsectionstatus";
  return await profileAPIService.fetchData(url);
}

export async function UpdateUserSectionStatus(){
  let url = "updatesectionstatus";
  return await profileAPIService.fetchData(url);
}
