import { Button, Form } from "react-bootstrap";
import "./MyProfile.css";
import { useEffect, useState } from "react";
import { GetUserQnA, SaveQnA } from "../../apis/ProfileAPI";
import useInterval from "use-interval";
import { RenderRadio } from "../../Components/FormInputs/RenderRadio";
import { RenderRadioText } from "../../Components/FormInputs/RenderRadioText";
import { RenderCheckboxText } from "../../Components/FormInputs/RenderCheckboxText";

var changeDetectedComm = false;

export default function Communication(prop) {
  const [communicationData, setCommunicationData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [autoSaveDelay] = useState(2 * 60 * 1000);

  const defaultButtonText = {
    save: "Save",
    submit: "Submit",
  };
  const [buttonText, setButtonText] = useState(defaultButtonText);

  useEffect(() => {
    const GetData = async () => {
      try {
        const result = await GetUserQnA("communication", prop.userid ?? 0);

        // Create a new array with updated questions
        const updatedQuestions = result.map((element) => ({
          ...element,
          answer: {
            value: element.answer,
            isInvalid:
              element.answer === "" ||
              element.answer === null ||
              element.answer === "null",
            mode: "save",
          },
        }));

        // Update state with the new array
        setCommunicationData(updatedQuestions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetData();
    setButtonText(defaultButtonText);
  }, []);

  const updateQnA = (qn, ans, mode = "save") => {
    if (prop.autoSaveMode.communication) changeDetectedComm = true;
    setCommunicationData((prevCommunicationData) => {
      return prevCommunicationData.map((q) =>
        q.question_id === qn.question_id
          ? {
              ...q,
              answer: {
                ...q.answer,
                value: ans,
                isInvalid: ans === "" || ans === null,
                mode: mode,
              },
            }
          : q
      );
    });
  };

  const updateMode = (mode) => {
    const qnaData = [...communicationData];
    // Update mode for userQnA
    if (qnaData) {
      if (Array.isArray(qnaData)) {
        // Handle array of questions
        qnaData.forEach((qn) => {
          qn.answer.mode = mode;
        });
      }
    }
    setCommunicationData(qnaData);
  };

  const savecommunication = async (mode, type) => {
    if (type !== "auto")
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: mode === "save" ? "Saving..." : "Submitting...",
      }));
    var qna = [];
    communicationData.forEach((item) => {
      qna.push({ QuestionId: item.question_id, Answer: item.answer.value });
    });

    const abtMe = {
      userId: 0,
      firstName: "",
      lastName: "",
      phoneNo: "",
      gender: "",
      birthPlace: "",
      address: "",
      city: "",
      qnA: qna,
      mode: mode === "save" ? 0 : 1,
    };

    if (mode === "save") {
      updateMode("save");
      await SaveQnA(abtMe);
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: "Saved",
      }));
      setDisableBtn(false);
      setDefaultButtonText();
    } else if (mode === "submit") {
      updateMode("submit");
      var isValid = isObjectValid(communicationData);
      if (isValid) {
        await SaveQnA(abtMe);
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Submitted",
        }));
        setDefaultButtonText();
      } else {
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Fill all the fields.",
        }));
        setDefaultButtonText(5000);
      }
      setDisableBtn(false);
    }
  };

  const isObjectValid = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].answer.isInvalid) {
          return false;
        }
      }
    }
    return true; // All properties have valid values
  };

  useInterval(() => {
    if (prop.autoSaveMode.communication) {
      autoSaveData();
    }
  }, autoSaveDelay);

  function autoSaveData() {
    if (changeDetectedComm) {
      savecommunication("save", "auto");
      setButtonText((prevState) => ({ ...prevState, save: "Auto Saving..." }));
    }
  }

  const handleCheckboxChange = (event, item) => {
    const value = event.target.value;
    let hasOther = false;

    const startsWithOther = (option) => option.startsWith("Other^");
    var schk =
      item.answer.value == null || item.answer.value == ""
        ? []
        : item.answer.value.split("~");
    if (value === "Other") {
      hasOther = schk.some((option) => option.startsWith("Other^"));
    }
    if (schk.includes(value) || hasOther) {
      if (hasOther) {
        schk = schk.filter((option) => !startsWithOther(option));
      } else {
        schk = schk.filter((option) => option !== value);
      }
    } else {
      schk = [...schk, value];
    }
    updateQnA(item, schk.join("~"));
  };

  const handleOtherText = (event, item) => {
    const value = `Other^${event.target.value}`;
    var schk =
      item.answer.value == null || item.answer.value == ""
        ? []
        : item.answer.value.split("~");
    schk = schk.filter((option) => option.indexOf("Other") === -1);
    schk = [...schk, value];
    updateQnA(item, schk.join("~"));
  };

  const setDefaultButtonText = (t = 3000) => {
    changeDetectedComm = false;
    setTimeout(() => {
      setButtonText(defaultButtonText);
    }, t);
  };

  return (
    <div className="my-profile-info">
      <Form>
        {communicationData.map((item, indx) =>
          item.field_type === "radio" ? (
            <RenderRadio item={item} updateQnA={updateQnA} />
          ) : item.field_type === "radio_text" ? (
            <RenderRadioText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "checkbox_text" ? (
            <RenderCheckboxText
              item={item}
              handleCheckboxChange={handleCheckboxChange}
              handleOtherText={handleOtherText}
            />
          ) : (
            ""
          )
        )}
        {!prop.userid && (
          <div className="submit-action-btns">
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => savecommunication("save")}
              disabled={disableBtn}
            >
              {buttonText.save}
            </Button>{" "}
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => savecommunication("submit")}
              disabled={disableBtn}
            >
              {buttonText.submit}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}
