import React from 'react'
import './PrivacyPolicy.css'; 
import { Col, Container, Row } from 'react-bootstrap'
import BookConsultation from '../../Components/BookConsultation/BookConsultation'
import PolicyBnrLight from "../../images/policy-bnr-light.png";
import PolicyBnrImg from "../../images/policy-bnr-couple.png";
import { useParallax } from 'react-scroll-parallax';

export default function PrivacyPolicy() {
    const parallax0 = useParallax({
        translateX: [-200, 20, "easeOutQuint"],
        translateY: [0, 0, "easeInQuint"],
    });
  return (
    <>
        <section className='policy-bnr'>
            <Container>
                <div className='policy-bnr-light' data-aos="zoom-in" data-aos-duration="500">
                    <img src={PolicyBnrLight} alt="Tow To Tango" />
                </div>
                <Row>
                    <Col lg={7} md={12} className='policy-bnr-left-col' data-aos="zoom-in" data-aos-duration="500">
                        <h2>Privacy Policy</h2> 
                    </Col>
                    <Col lg={5} md={12} className='policy-bnr-right-col' data-aos="fade-left" data-aos-duration="500">
                        <img src={PolicyBnrImg} alt="Tow To Tango" ref={parallax0.ref} />
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section>
        <section className='policy-content'>
            <Container>
                <Row>
                    <Col lg={12} md={12} className='policy-content-col'>
                        <div className='policy-content-col-inner'>
                            <p data-aos="fade-up" data-aos-duration="500">The Brand Two 2 Tango and the said site is owned and operated by ARMEIS INC., a company incorporated under the laws of the State of California and having its office at 1330 Avenue of the Americas, Suite 23A, New York, NY 10019. ARMEIS INC is a wholly owned subsidiary of ARMEIS ADVISORY PRIVATE LIMITED, a company incorporated under the Laws of India with its office at Level 3B, DLF Centre, Sansad Marg, Connaught Place, New Delhi-110001. For any query, concern or grievance in relation to the instant agreement, please free to contact us at email ID: <a href='mailto:contactus@two2tango.com'>contactus@two2tango.com</a>.</p>  
                            <p data-aos="fade-up" data-aos-duration="1000">This Privacy Policy applies to all the businesses of TTT and the information that is received by TTT. It does not apply to practices of businesses that we do not own or control or people we do not employ.</p>
                            <h3 data-aos="fade-up" data-aos-duration="1000"><span>Member Information: Direct & Indirect</span></h3>
                            <h4 data-aos="fade-up" data-aos-duration="1500">Direct Information:</h4> 
                            <p data-aos="fade-up" data-aos-duration="1500">When you register or subscribe to any of our services, we collect a wide variety of information about you that can be used to personally identify you as an individual (including but not limited to name, address, telephone number, photograph, etc). We also ask you to provide us with answers to our proprietary questionnaire, and we use your answers to build a personality profile for you so as to be able to match you with other compatible singles. This may contain questions including but not limited to your physical traits, habits, likes, dislikes, personality and views on self, marriage and the kind of partner you are looking for.</p>
                            <p data-aos="fade-up" data-aos-duration="1500">We display information as provided by you or someone who is acting on your behalf to create your profile. We do not guarantee the authenticity of this information, and it is assumed to be accurate and not misrepresent any facts. Once you click on the submit button, the information is sent to the administrative department, and changes to it may or may not be possible thereafter.</p>
                            <h4 data-aos="fade-up" data-aos-duration="2000">Indirect Information:</h4>
                            <p data-aos="fade-up" data-aos-duration="2000">This refers to information that is not directly provided by you and cannot be used to identify you, but is obtained as a result of your actions, such as visiting the TTT website directly or through a third party link or social media platform. Any time you visit TTT, our servers automatically gather information from your browser including but not limited to IP addresses, browser type, Internet Service Provider (ISP), platform type etc.</p>
                            <p data-aos="fade-up" data-aos-duration="2000">Similarly, if our services are accessed by the mobile phone, or if any of our applications are downloaded, data regarding the phone type, model, software functionality etc may be collected. This data is used to analyse trends, administer the site, prevent fraud, track visitor movement in the aggregate, and gather broad demographic information</p>
                            <h3 data-aos="fade-up" data-aos-duration="2500"><span>Cookies</span></h3>
                            <p data-aos="fade-up" data-aos-duration="2500">We use “cookies” to keep track of some types of information while you are visiting our website or using our services. They also allow us to save user preferences and track user trends. We rely on cookies for the proper operation of our website; therefore, if your browser is set to reject all cookies, the website will not function properly. Users who refuse cookies assume all responsibility for any resulting loss of functionality.</p>
                            <p data-aos="fade-up" data-aos-duration="3000">Please note that cookies are used only to recollect information sent to your computer from vowsforeternity.com. We CANNOT access any information not sent by our website. Some of our business partners may use cookies on their site (for example, advertisers). However, we have no access to or control over these cookies.</p>
                            <h3 data-aos="fade-up" data-aos-duration="3500"><span>Payment Information & Internet Security</span></h3>
                            <p data-aos="fade-up" data-aos-duration="3500">To process purchases, we may require your name, address, phone number, email address and credit card information. Such information is used primarily to process your order or as otherwise described herein. Credit card transactions are processed through industry standard Secure Socket Layer (SSL) and/or strong encryption (3DES) by a third party, which only uses your information for that purpose. We do try our best to provide a secure connection; however we cannot guarantee perfect security in the online space and will not accept any unintentional disclosure liabilities.</p>
                            {/* <h3 data-aos="fade-up" data-aos-duration="4000"><span>Payment Information & Internet Security</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4000">To process purchases, we may require your name, address, phone number, email address and credit card information. Such information is used primarily to process your order or as otherwise described herein. Credit card transactions are processed through industry standard Secure Socket Layer (SSL) and/or strong encryption (3DES) by a third party, which only uses your information for that purpose. We do try our best to provide a secure connection; however, we cannot guarantee perfect security in the online space and will not accept any unintentional disclosure liabilities.</p>  */}
                            <p data-aos="fade-up" data-aos-duration="4000">When you make a purchase, we keep the details of the transaction (e.g., what you purchased, the date of the transaction, and the price). The exact data depends on the payment method you choose. When you pay directly to us (rather than through a third party), you provide your debit or credit card number or other financial info.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">If any user/client/subscriber has any query/concern regarding the payment/subscription fee/subscription package, etc. they can contact us at <a href='mailto:contactus@two2tango.com'>contactus@two2tango.com</a>.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4000"><span>Refer A Friend</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4000">We encourage you to refer a friend to us by providing us with your friend’s name and email address. We will keep this information in our database and send your friend a one-time e-mail that includes your name, inviting them to visit our site. </p> 
                            <p data-aos="fade-up" data-aos-duration="4000">You agree not to abuse this feature by entering the names and addresses of those who would not be interested in TTT’s services or are ineligible for them.</p> 
                            <h3 data-aos="fade-up" data-aos-duration="4000"><span>Third-Parties & Sharing Of Personal Data</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4000">Your personal information is not sold to any third party by TTT. However, we may disclose information you provide when required to do so by law, if we, in our sole discretion, believe that disclosure is required to </p> 
                            <ol>
                                <li>comply with the law, requests or orders from law enforcement, or any legal process (whether or not such disclosure is required by applicable law); </li>
                                <li>protect or defend TTT’s, or a third party’s, rights or property; or</li>
                                <li>protect someone’s health or safety, such as when harm or violence against any person (including you) is feared.</li>
                            </ol>
                            <p data-aos="fade-up" data-aos-duration="4000">As TTT is a personalised search, it cannot carry out its work without sharing your information and personal details along with any photograph with another member who is considered a potential match. Once information is shared the other person may or may not express an interest in the member whose profile is sent. Given the personalized nature of our service, you will be contacted via email/phone. In addition, you may also receive promotional notifications. As it is not possible to provide the personalised search service without severely compromising the service offered to you, therefore we do not offer the option of being able to opt out of this feature.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">In addition, every request or interest expressed by you or someone else is sent to our administration section and is stored for future matchmaking in order to try and find the right match for you. On an ongoing basis, TTT may ask a member to provide additional details to improve the services provided. The member is responsible for the authenticity of the information he/she provides as well as the safety of it by using a secure password.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">We also have a discussion board/events page. This can be easily accessed by and is visible to every member. Please exercise caution whilst displaying any personal information in that section, as it may not be possible to remove it later.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">TTT may need to share your information to a trusted third party so that they can assist in providing a service for you.</p>  
                            <p data-aos="fade-up" data-aos-duration="4000">TTT may include links to other websites but is not responsible for any third party as each website is governed by its own privacy policies. Equally, you may also be able to access your account or the content of your account from third-party sites, such as social networking sites, by way of various applications. It is important that you make sure that you are comfortable with what their policies are before divulging any information. You need to contact the third-party website directly for their privacy policy. Third-party operators will have their own cookies and other monitoring tools. TTT is not responsible for any actions once you click on a third-party operator link.</p>  
                            <h3 data-aos="fade-up" data-aos-duration="4000"><span>Acceptance Policy & Changes</span></h3>
                            <p data-aos="fade-up" data-aos-duration="4000">Acceptance Policy: Any use of the website implies the acceptance of the entire Privacy Policy.</p> 
                            <p data-aos="fade-up" data-aos-duration="4000">Changes: TTT reserves the right to amend the Privacy Policy at any time. Any changes made will be posted on this statement. Your continued use of any portion of our website following posting of the updated privacy statement will constitute your acceptance of the changes. Please make sure you check our Terms & Conditions and Privacy Policy before confirming anything.</p> 
                            <h4>Last change made: 21<sup>st</sup> June 2024</h4>
                         </div>
                    </Col> 
                </Row>
            </Container> 
        </section>
    </>
  )
}
