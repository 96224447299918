import React from 'react'
import './UpgradeOptions.css'; 
import { Col, Container, ListGroup, Row } from 'react-bootstrap'; 
import { Link } from 'react-router-dom'; 
import MembershipPackagesDancingCouple1 from "../../images/membership-packages-dancing-couple1.png";
import MembershipPackagesDancingCouple2 from "../../images/membership-packages-dancing-couple2.png";
import PlusMembership3colImg1 from "../../images/concierge-service-3col1.jpg";
import PlusMembership3colImg2 from "../../images/concierge-service-3col2.jpg";
import PlusMembership3colImg3 from "../../images/concierge-service-3col3.jpg";
import PlusMembership5colImg1 from "../../images/concierge-service-5col1.jpg";
import PlusMembership5colImg2 from "../../images/concierge-service-5col2.jpg";
import PlusMembership5colImg3 from "../../images/concierge-service-5col3.jpg";
import PlusMembership5colImg4 from "../../images/concierge-service-5col4.jpg";
import PlusMembership5colImg5 from "../../images/concierge-service-5col5.jpg"; 
import { useParallax } from 'react-scroll-parallax';

export default function UpgradeOptions() {

 
    const parallax2 = useParallax({
        translateX: [50, 0, "easeOutQuint"],
        translateY: [0, 0, "easeInQuint"],
    });
    const parallax3 = useParallax({
        translateX: [-50, 0, "easeOutQuint"],
        translateY: [0, 0, "easeInQuint"],
    });
  return (
    <>
    <section className='membership-packages'>
        <Container>
            <Row>
                <Col lg={4} md={12} className='membership-packages-img'>
                    <div className='membership-packages-img-inner'>
                        <img src={MembershipPackagesDancingCouple1} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" ref={parallax2.ref} />
                    </div>
                </Col>
                <Col lg={8} md={12} className='membership-packages-info'>
                    <div className='membership-packages-info-inner'>
                        <div className='membership-packages-head' data-aos="zoom-in" data-aos-duration="500">
                            <div className='membership-packages-name'>
                                <span>Tango</span> 
                            </div>
                            <div className='membership-packages-price'>
                                <span>Rs. 45,000</span>
                            </div>
                            <div className='membership-packages-btn'>
                                <Link to="/SignUp">Sign Up</Link>
                            </div>
                        </div>
                        <div className='membership-packages-choose-info'>
                            <h6 data-aos="fade-up" data-aos-duration="500">Choose your path to love with our 6-month membership</h6>
                            <p data-aos="fade-up" data-aos-duration="1000">This Tango membership offers a curated, offline journey to genuine connections.</p>
                        </div>
                        <div className='membership-packages-detail-info'>
                            <div className='membership-packages-detail-info-head'>
                                <h4 data-aos="zoom-in" data-aos-duration="500"><span>What awaits you</span></h4>
                            </div>
                            <Row className='membership-packages-detail-3col-row'>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Crafted Matches:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Our experienced matchmakers personally select individuals who mirror your aspirations, values,
and lifestyle. No algorithms, just real compatibility.</p>
                                </Col>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Beyond Bois:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Enjoy confidential, in-person meetings with compatible individuals, and a chance
to connect and explore chemistry with real-life interactions.</p>
                                </Col>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Support and Guidance:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Throughout your journey, our team will be there to offer guidance and support, ensuring your dating experience
is enjoyable and fulfilling.</p>
                                </Col>
                            </Row>
                            <div className='deliverables-list'>
                                <div className='deliverables-list-head'>
                                    <h4 data-aos="zoom-in" data-aos-duration="500"><span>Deliverables you can tango with</span></h4>
                                </div>
                                <ListGroup>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="500">6 curated introductions with individuals hand-picked for you.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="1000">Confidentiality and discretion throughout your experience.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="1500">Support in planning and logistics for your offline introductions. </ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="2000">Updates to our exclusive member events to expand your social circle.</ListGroup.Item> 
                                </ListGroup> 
                            </div>
                        </div>
                    </div>
                </Col> 
            </Row>
        </Container>  
    </section> 
    <section className='membership-packages2'>
        <Container>
            <Row> 
                <Col lg={8} md={12} className='membership-packages-info'>
                    <div className='membership-packages-info-inner'>
                        <div className='membership-packages-head' data-aos="zoom-in" data-aos-duration="500">
                            <div className='membership-packages-name'>
                                <span>Tango Plus</span> 
                            </div>
                            <div className='membership-packages-price'>
                                <span>Rs. 75,000</span>
                            </div>
                            <div className='membership-packages-btn'>
                                <Link to="/SignUp">Sign Up</Link>
                            </div>
                        </div>
                        <div className='membership-packages-choose-info'>
                            <h6 data-aos="fade-up" data-aos-duration="500">Waltz into love with our 1-year membership</h6>
                            <p data-aos="fade-up" data-aos-duration="1000">Our 12-month membership invites you to experience that kind of love through carefully curated offline connections.</p>
                        </div>
                        <div className='membership-packages-detail-info'>
                            <div className='membership-packages-detail-info-head'>
                                <h4 data-aos="zoom-in" data-aos-duration="500"><span>What awaits you</span></h4>
                            </div>
                            <Row className='membership-packages-detail-3col-row'>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Evolving Matches:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Throughout your year, our matchmakers adapt their expertise to your preferences, introducing you to a diverse range of compatible individuals.</p>
                                </Col>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Deeper Connections:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Engage in multiple, in-person meetings with potential partners, fostering meaningful interactions that go beyond the superficial.</p>
                                </Col>
                                <Col lg={4} md={4} className='membership-packages-detail-3col'>
                                    <h5 data-aos="fade-up" data-aos-duration="500">Priority Access:</h5>
                                    <p data-aos="fade-up" data-aos-duration="1000">Enjoy privileged entry to exclusive events and gatherings, expanding your social circle and opening
doors to new encounters.</p>
                                </Col>
                            </Row>
                            <div className='deliverables-list'>
                                <div className='deliverables-list-head'>
                                    <h4 data-aos="zoom-in" data-aos-duration="500"><span>Deliverables you can tango with</span></h4>
                                </div>
                                <ListGroup>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="500">12 curated introductions with individuals hand-picked for you.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="1000">Confidentiality and discretion throughout your experience.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="1500">Expert coaching on communication, confidence, and navigating the dating landscape.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="2000">Support in planning and logistics for your offline introductions.</ListGroup.Item>
                                    <ListGroup.Item data-aos="fade-up" data-aos-duration="2500">Priority access to exclusive member events to expand your social circle.</ListGroup.Item> 
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </Col> 
                <Col lg={4} md={12} className='membership-packages-img'>
                    <div className='membership-packages-img-inner'>
                        <img src={MembershipPackagesDancingCouple2} alt="Tow To Tango" data-aos="fade-up-right" data-aos-duration="500" ref={parallax3.ref} />
                    </div>
                </Col>
            </Row>
        </Container>  
    </section>
    <section className='concierge-service'>
        <Container>
            <Row> 
                <Col lg={12} md={12} className='concierge-service-top'>
                    <div className='concierge-service-inner'>
                        <div className='membership-packages-head'>
                            <div className='membership-packages-name' data-aos="zoom-in" data-aos-duration="500">
                                <span>Luxe Concierge Service</span> 
                            </div>  
                        </div>
                        <div className='membership-packages-choose-info'>
                            <h6 data-aos="fade-up" data-aos-duration="500">Elevate Your Dating Journey with Two 2 Tango Luxe</h6>
                            <p data-aos="fade-up" data-aos-duration="1000">You joined Two 2 Tango because you believe in genuine connections. Now, unlock the door to love amplified with our Luxe Concierge Service.</p>
                        </div>
                        <div className='concierge-service-2col'>
                            <div className='concierge-service-col'>
                                <div className='membership-packages-head' data-aos="fade-up-left" data-aos-duration="500">
                                    <div className='membership-packages-name'>
                                        <span>Tango Luxe</span>
                                        <span> : Rs. 45,000 + 20,000</span>
                                    </div> 
                                    <div className='membership-packages-btn'>
                                        <Link to="/SignUp">Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='concierge-service-col'>
                                <div className='membership-packages-head' data-aos="fade-up-right" data-aos-duration="500">
                                    <div className='membership-packages-name'>
                                        <span>Tango Luxe</span>
                                        <span> : Rs. 45,000 + 20,000</span>
                                    </div> 
                                    <div className='membership-packages-btn'>
                                        <Link to="/SignUp">Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='plus-membership-packages-detail-info'> 
                            <Row className='plus-membership-packages-detail-3col-row'>
                                <Col lg={3} md={12} className='plus-membership-packages-detail-3col'>
                                    <div className='plus-membership-3col-img'>
                                        <img src={PlusMembership3colImg1} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-3col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Personalised Matches:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Expert matchmakers go beyond profiles, finding matches who truly resonate with your soul.</p>    
                                    </div> 
                                </Col>
                                <Col lg={6} md={12} className='plus-membership-packages-detail-3col'>
                                    <div className='plus-membership-3col-img'>
                                        <img src={PlusMembership3colImg2} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-3col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Personalised Matches:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Craft an irresistible profile that captures your essence, leaving potential partners captivated. We help you create an authentic and appealing profile that showcases your best attributes and communicates what makes you special. From selecting the right photos to crafting a captivating bio, we help you put your best foot forward in the online dating world.</p>    
                                    </div>  
                                </Col>
                                <Col lg={3} md={12} className='plus-membership-packages-detail-3col'>
                                    <div className='plus-membership-3col-img'>
                                        <img src={PlusMembership3colImg3} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-3col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Date Night Déjà Vu? Not Here:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Let our concierge curate unforgettable dates, be it a romantic dinner or a thrilling adventure.</p> 
                                    </div>  
                                </Col>
                            </Row>
                            <Row className='plus-membership-packages-detail-5col-row'>
                                <Col className='plus-membership-packages-detail-5col'>
                                    <div className='plus-membership-5col-img'>
                                        <img src={PlusMembership5colImg1} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-5col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Pre-date Jitters? Conquer Them:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Our dating coaches empower you with confidence and communication tools for seamless connections.</p> 
                                    </div>  
                                </Col>
                                <Col className='plus-membership-packages-detail-5col'>
                                    <div className='plus-membership-5col-img'>
                                        <img src={PlusMembership5colImg2} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-5col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Feedback is the Key:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Share your experiences, and we'll refine your journey based on your feedback, ensuring success.</p> 
                                    </div>  
                                </Col>
                                <Col className='plus-membership-packages-detail-5col'>
                                    <div className='plus-membership-5col-img'>
                                        <img src={PlusMembership5colImg3} alt="Tow To Tango" />
                                    </div> 
                                </Col>
                                <Col className='plus-membership-packages-detail-5col'>
                                    <div className='plus-membership-5col-img'>
                                        <img src={PlusMembership5colImg4} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-5col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Exclusive Events:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Mingle with like-minded individuals at glamorous gatherings, fostering new connections in a relaxed setting.</p> 
                                    </div>  
                                </Col>
                                <Col className='plus-membership-packages-detail-5col'>
                                    <div className='plus-membership-5col-img'>
                                        <img src={PlusMembership5colImg5} alt="Tow To Tango" />
                                    </div>
                                    <div className='plus-membership-5col-text'>
                                        <h5 data-aos="fade-up" data-aos-duration="500">Privacy is Paramount:</h5>
                                        <p data-aos="fade-up" data-aos-duration="1000">Rest assured, every member is rigorously verified for your peace of mind.</p> 
                                    </div>  
                                </Col>
                            </Row>  
                        </div>
                    </div>
                </Col>  
            </Row>
        </Container>  
    </section>
    </>
  )
}
