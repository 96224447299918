import React, { useEffect, useState } from "react";
import "./Login.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import LoginRightImg from "../../images/login-right-img.jpg";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import { ForgotPassword as fp } from "../../apis/UserAPI";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage";
import Loader from "../../Components/Spinner/Loader";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";

export default function ForgotPassword() {
  const initialAlarmState = {
    variant: "",
    message: "",
    showAlert: false,
    date: Date.now(),
  };
  const [alertData, setAlertData] = useState(initialAlarmState);

  const [userEmail, setUserEmail] = useState("");
  const [userCaptcha, setUserCaptcha] = useState({ value: "", invalid: false });
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const handleUserEmail = async (v) => {
    setUserEmail(v);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!regex.test(v)) {
      setInvalidMsg("Invalid Email");
      setIsEmailInvalid(true);
      setEnableButton(false);
    } else {
      setInvalidMsg("");
      setIsEmailInvalid(false);
      setEnableButton(true);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (validateCaptcha(userCaptcha.value)) {
      setShowLoader(true);
      var result = await fp(userEmail);
      setShowLoader(false);
      if (result === "1") {
        setEnableButton(false);
        setAlertData({
          variant: "Success",
          message: "An email sent to you with reset password link.",
          showAlert: true,
          date: Date.now(),
        });
      } else if (result === "0") {
        loadCaptchaEnginge(6, "#CDAC45", "white");
        setEnableButton(true);
        setAlertData({
          variant: "Dark",
          message: "Either user not exist or not active. Please contact admin.",
          showAlert: true,
          date: Date.now(),
        });
      }
    } else {
      setUserCaptcha({ value: "", invalid: true });
      loadCaptchaEnginge(6, "#CDAC45", "white");
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "#CDAC45", "white");
  }, []);

  return (
    <>
      <section className="login">
        <AlertMessage
          variant={alertData.variant}
          message={alertData.message}
          show={alertData.showAlert}
          delay={3000}
          date={alertData.date}
        />
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-left signup-form">
                <h2 data-aos="zoom-in" data-aos-duration="1000">
                  Reset Password
                </h2>
                <Form>
                  <Row className="mb-3">
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="EmailAddress"
                      label="Email Address"
                    >
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email Address"
                        value={userEmail}
                        onChange={(e) => handleUserEmail(e.target.value)}
                        isInvalid={isEmailInvalid}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {invalidMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="Captcha"
                      label=""
                    >
                      <LoadCanvasTemplateNoReload />
                      <Form.Control
                        type="text"
                        placeholder="captcha"
                        value={userCaptcha.value}
                        isInvalid={userCaptcha.invalid}
                        onChange={(e) =>
                          setUserCaptcha({
                            value: e.target.value,
                            invalid: false,
                          })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid Captcha
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Row>
                  <Button
                    className="login-btn"
                    type="button"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    disabled={!enableButton}
                    onClick={handleResetPassword}
                  >
                    RESET
                    <Loader visible={showLoader} />
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="login-right">
                <img src={LoginRightImg} alt="Tow To Tango" />
              </div>
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
    </>
  );
}
