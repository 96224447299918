import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "Stripe/";
const stripeAPIService = new APIService(baseUrl);

export async function CancelSubscription() {
  var url = "cancel-subscription";
  return await stripeAPIService.fetchData(url);
}

export async function UpdateSubscription(packageids) {
  var url = `update-subscription?packageids=${packageids}`;
  return await stripeAPIService.fetchData(url);
}
