import React from 'react'
import './Footer.css';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import FbIcon from "../../images/fb-icon.png";
import InIcon from "../../images/in-icon.png";
import InstaIcon from "../../images/insta-icon.png";
import { NavLink } from 'react-router-dom';

export default function Footer() {
  return (
    <>
        <footer>
        <Container>
            <Row>
                <Col lg={6} md={6} sm={7}>
                    <Nav className='footer-link'>
                        <Nav.Item>
                            <NavLink href="/TermsConditions" to="/TermsConditions">Terms & Conditions</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink href="/PrivacyPolicy" to="/PrivacyPolicy">Privacy Policy</NavLink>
                        </Nav.Item> 
                    </Nav>
                </Col>
                <Col lg={6} md={6} sm={5}>
                    <Nav className='footer-social'>
                        <Nav.Item>
                            <Nav.Link href="https://www.facebook.com/Two2TangoOfficial?mibextid=ZbWKwL" target='_blank'><img src={FbIcon} alt="TTT FB" /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="https://www.linkedin.com/company/two2tangoofficial/" target='_blank'><img src={InIcon} alt="TTT IN" /></Nav.Link>
                        </Nav.Item> 
                        <Nav.Item>
                            <Nav.Link href="https://www.instagram.com/two2tangoofficial/?utm_source=ig_web_button_share_sheet" target='_blank'><img src={InstaIcon} alt="TTT Insta" /></Nav.Link>
                        </Nav.Item> 
                    </Nav>
                </Col>
            </Row>
        </Container>
        </footer>
    </>
  )
}
