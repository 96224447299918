import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "MyMatches/";

const matchesAPIService = new APIService(baseUrl);

export async function GetMyMatches() {
  let url = `getmymatches`;
  return await matchesAPIService.fetchData(url);
}

export async function AddFeedback(feedback) {
  let url = "addfeedback";
  return await matchesAPIService.postData(url, feedback);
}

export async function GetUserFeedback(matchId){
  let url = `getuserfeedback?matchId=${matchId}`;
  return await matchesAPIService.fetchData(url);
}
