import React from 'react'
import './AboutUs.css'; 
import { Col, Container, ListGroup, Row } from 'react-bootstrap'; 
import LadyDancing from "../../images/lady-dancing.png";
import LetsShowImg1 from "../../images/lets-show-img1.jpg";
import LetsShowImg2 from "../../images/lets-show-img2.jpg";
import LetsShowImg3 from "../../images/lets-show-img3.jpg";
import OldMusicSystem from "../../images/about-btm-bg.png";
import BookConsultation from '../../Components/BookConsultation/BookConsultation';
import { useParallax } from 'react-scroll-parallax';


export default function AboutUs() { 
    const parallax0 = useParallax({
        translateX: [-100, 10, "easeOutQuint"],
        translateY: [0, 0, "easeInQuint"],
    });
  return (
    <>
        <section className='about-bnr'>
            <Container>
                <Row>
                    <Col lg={5} md={12} className='about-bnr-left-col'>
                        
                    </Col>
                    <Col lg={7} md={12} className='about-bnr-right-col'>
                        <h4 data-aos="fade-up" data-aos-duration="500">Your navigators in a</h4>
                        <h2 data-aos="fade-up" data-aos-duration="1000">sea of possibilities</h2> 
                        {/* <p data-aos="fade-up" data-aos-duration="1500">In this vast ocean of possibilities, we navigate alongside you, charting a course towards shores where true hearts meet.</p> */}
                        <p data-aos="fade-up" data-aos-duration="1500">8 global cities, one earnest goal. Together, we lead your search for love across New York, San Francisco, Dubai, London, Mumbai, New Delhi, Singapore, and Hong Kong.</p>
                    </Col>
                </Row>
            </Container> 
            <BookConsultation/> 
        </section>
        <section>
            <Container>
                <Row>
                    <Col>
                        <div className='about-head' data-aos="zoom-in" data-aos-duration="2000">
                            <h2>Every heart has a <span>story</span>, every soul a <span>destination</span>.</h2> 
                        </div>
                        <div className='about-info'>
                            <p data-aos="fade-up" data-aos-duration="1000">Whether your journey has weathered storms of change, experienced the gentle ebb and flow of time, or stands poised for its first exhilarating voyage, you’ll find encouragement, inspiration, and shared experiences in our diverse group. </p> 
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className='about-story-col-left'>
                            <img src={LadyDancing} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" ref={parallax0.ref} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='about-story-col-right' data-aos="zoom-in" data-aos-duration="1000"></div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='lets-show-section'>
            <Container>
                <Row>
                    <Col>
                        <div className='about-head' data-aos="zoom-in" data-aos-duration="1000">
                            {/* <h2>Let’s show them <span>how its done</span></h2> */}
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg1} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg2} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg3} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                </Row> */}
                <Row className='lets-show-3col'>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={LetsShowImg1} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">Authenticity and Intent:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Our members are looking for that meaningful connection; therefore, honesty and transparency take centre stage.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={LetsShowImg2} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">Quality over Quantity:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Our members are not looking for numbers, but the right one. The curation and personalisation increase the chances of finding the one.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='why-3col'>
                            <div className='why-3col-img'>
                                <img src={LetsShowImg3} alt="Tow To Tango" data-aos="fade-up" data-aos-duration="1000" />
                            </div>
                            <div className='why-3col-text'>
                                <div className='why-3col-text-info1'>
                                    <h6 data-aos="fade-up" data-aos-duration="500">Enhanced Experience:</h6>
                                    <p data-aos="fade-up" data-aos-duration="1000">Our members are successful, and time is their greatest luxury. They value our hand holding immensely, so that they can make an informed decision about whether to engage further.</p>
                                </div> 
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='lets-show-para'>
                            {/* <p data-aos="fade-up" data-aos-duration="1000">Our curated search eliminates the noise. Gone are the incompatible connections, the endless first dates that lead nowhere, and the annoying aunties that ask you to compromise!</p>
                            <p data-aos="fade-up" data-aos-duration="1500">Instead, you encounter individuals aligned with your vision, sparking conversations that resonate on a deeper level.</p> */}
                            {/* <ListGroup  data-aos="fade-up" data-aos-duration="1000">
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                >Authenticity and Intent — Our members are looking for that meaningful connection; therefore, honesty and transparency take centre stage.</ListGroup.Item>
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >Quality over Quantity — Our members are not looking for numbers, but the right one. The curation and personalisation increase the chances of finding the one.</ListGroup.Item>
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                >Enhanced Experience — Our members are successful, and time is their greatest luxury. They value our hand holding immensely, so that they can make an informed decision about whether to engage further.</ListGroup.Item> 
                            </ListGroup>   */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section>
            <Container>
                <Row>
                    <Col>
                        <div className='about-btm'>
                            <img src={OldMusicSystem} alt="Tow To Tango" data-aos="fade-in-up" data-aos-duration="1000" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
