import { ResolveBaseApiUrl } from "../utils/Utility";
import APIService from "./APIService";

const baseUrl = ResolveBaseApiUrl() + "User/";

const userAPIService = new APIService(baseUrl);

export async function SignupUser(userdata) {
  let url = "signup";
  return await userAPIService.postData(url, userdata);
}

export async function EmailExists(email) {
  let url = `emailexists?email=${email}`;
  return await userAPIService.fetchData(url);
}

export async function ValidateLogin(username, passwrd) {
  let url = `validateLogin?username=${username}&password=${passwrd}`;
  return await userAPIService.fetchData(url);
}

export async function ForgotPassword(useremail) {
  let url = `forgotpassword?useremail=${useremail}`;
  return await userAPIService.fetchData(url);
}

export async function ResetPassword(data) {
  let url = "resetpassword";
  return await userAPIService.postData(url, data);
}

export async function ChangePassword(oldpassword, newpassword) {
  let url = `changepassword?oldpassword=${oldpassword}&newpassword=${newpassword}`;
  return await userAPIService.fetchData(url);
}

export async function GetSignUpQ() {
  let url = "getusersignupquestion";
  return await userAPIService.fetchData(url);
}

export async function AddEnquiry(data) {
  let url = "addcontactusenquiry";
  return await userAPIService.postData(url, data);
}

export async function VerifyUser(userid) {
  let url = `verifyuser?userid=${userid}`;
  return await userAPIService.fetchData(url);
}

export async function ActivateUser(data) {
  let url = "activateuser";
  return await userAPIService.postData(url, data);
}
