import React from 'react'
import './RelationshipManager.css'; 
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import RelationshipManagerImg from "../../images/relationship-manager.jpg";

export default function RelationshipManager() {
  return (
    <>
        <section className='relationship-manager'>
            <Container>
                <Row>
                    <Col lg={5} className='relationship-manager-left-col'>
                        <div className='relationship-manager-left' data-aos="fade-up" data-aos-duration="1000">
                            <img src={RelationshipManagerImg} alt="Tow To Tango" />
                        </div>
                    </Col>
                    <Col lg={7} className='relationship-manager-right-col'>
                        <div className='relationship-manager-right'>
                            <div className='relationship-manager-right-inner'>
                                <div className='relationship-manager-right-inner-items' data-aos="fade-left" data-aos-duration="2000" data-aos-easing="ease-in-sine">
                                    <h3>Damini Kapoor</h3>
                                    <Form className='relationship-manager-form'>
                                        <Row className="mb-3">
                                            <FloatingLabel as={Col} md="12" controlId="Email ID" label="Email ID"> 
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email ID" 
                                            /> 
                                            </FloatingLabel>

                                            <FloatingLabel as={Col} md="12" controlId="PhoneNumber" label="Phone Number"> 
                                            <Form.Control
                                                required
                                                type="number"
                                                placeholder="Phone Number" 
                                            /> 
                                            </FloatingLabel>  
                                        </Row> 
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container> 
        </section>
    </>
  )
}
