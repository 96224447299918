import React from 'react'
import './FAQs.css'; 
import { Accordion, Col, Container, ListGroup, Row } from 'react-bootstrap'
import BookConsultation from '../../Components/BookConsultation/BookConsultation'
import FaqBnrImg from "../../images/faq-bnr-vector.png";
import FaqRightImg from "../../images/faqs-right-img.jpg";

export default function FAQs() {
  return (
    <>
        <section className='faqs-bnr'>
            <Container>
                <Row>
                    <Col lg={7} md={12} className='faqs-bnr-left-col'>
                        <h2 data-aos="zoom-in" data-aos-duration="1000">Frequently Asked Questions</h2> 
                    </Col>
                    <Col lg={5} md={12} className='faqs-bnr-right-col'>
                        <img src={FaqBnrImg} alt="Tow To Tango" />
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section> 
        <section className='faqs-content'>
            <Container>
                <Row>
                    <Col lg={8} md={12} className='faqs-content-left-col'>
                        <div className='faqs-accordian'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" data-aos="zoom-in" data-aos-duration="500">
                                    <Accordion.Header>What is Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    Two 2 Tango is a premium, offline matchmaking service, exclusively designed for individuals who seek genuine connections and meaningful relationships beyond the online realm. We prioritise face-to-face interactions and personalised matchmaking to enhance the chances of finding compatible partners.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What cities are you based in?</Accordion.Header>
                                    <Accordion.Body>
                                    New York, San Francisco, London, Dubai, New Delhi, Mumbai, Singapore and Hong Kong. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How does your service work?</Accordion.Header>
                                    <Accordion.Body>
                                    Once you join Two 2 Tango, our team of dedicated matchmakers will get to know you personally, taking into account your interests, values, and life experiences. Based on this information, we will carefully select potential matches and facilitate confidential meetings in person or over a video call depending on your location.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How do I get started with Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    If you are someone with a strong sense of gut who knows their mind and you feel a resonance with us, sign up and let's get started.<br /><br />

                                    If you like to tread carefully and mull over things, complete our “Book a consult” form. We can have a conversation, and you can test the waters. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5-1" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Does Two 2 Tango have an eligibility criterion?</Accordion.Header>
                                    <Accordion.Body>
                                    Our member eligibility consists of both tangible and intangible criteria. At Two 2 Tango, we focus on singles seeking meaningful relationships beyond casual dating. Tangibly, our members are accomplished professionals, executives and entrepreneurs who value their time and are looking to meet like-minded individuals with discretion and privacy at the center stage.<br /><br />

                                    Every member is unique and our intangible criteria aims to create a pool of high-quality, compatible individuals who are serious about finding long-term partners enhancing the overall success rate of the matchmaking service.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How are matches selected?</Accordion.Header>
                                    <Accordion.Body>
                                    Matches are selected based on a combination of your preferences and our matchmakers' expertise. We consider factors such as shared interests, values, and life goals to ensure compatibility. Our goal is to present you with the most suitable potential partners for offline meetings.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How long before I get the first profile?</Accordion.Header>
                                    <Accordion.Body>
                                    The matchmaking duration varies based on preferences, location, and availability. Rest assured, we’re as eager for the meet-cute stories and first encounters to happen as you are!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What happens during the offline meetings?</Accordion.Header>
                                    <Accordion.Body>
                                    Offline meetings are private and relaxed introductions arranged at a mutually agreed-upon location or on video call. They provide an opportunity to get to know your match on a deeper level and explore the potential for a meaningful relationship.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="7" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What makes your service different from other dating sites?</Accordion.Header>
                                    <Accordion.Body>
                                        <ListGroup  data-aos="fade-up" data-aos-duration="1000">
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="500"
                                            >Authenticity and Intent—Our members are looking for that meaningful connection, and therefore, honesty and transparency take centre stage.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1000"
                                            >Quality over Quantity — Our members are not looking for numbers; they are looking for the right one. The curation and personalisation increase the chances of finding the one.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1500"
                                            >Enhanced Experience—Our members are successful, and time is the greatest luxury. They value the handholding immensely, and they appreciate the detailed profile that we share so that they can make an informed decision about whether to engage further.</ListGroup.Item> 
                                        </ListGroup>  
 
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="8" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Why should I choose an offline matchmaking service?</Accordion.Header>
                                    <Accordion.Body>
                                    Offline matchmaking offers a more authentic and intimate approach to finding love. Studies have shown that face-to-face interactions lead to deeper connections and higher relationship satisfaction.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How do I know if this service is right for me?</Accordion.Header>
                                    <Accordion.Body>
                                    Our service is designed for individuals who are:
                                    <ListGroup  data-aos="fade-up" data-aos-duration="1000">
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="500"
                                            >Looking for something credible and believe in the value add that curation brings.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1000"
                                            >Tired of swiping endlessly on dating apps or feel like they're not making progress in their search.</ListGroup.Item>
                                            <ListGroup.Item
                                                data-aos="fade-up"
                                                data-aos-duration="1500"
                                            >Wanting to get back into testing the waters but unsure of how or where to start.</ListGroup.Item> 
                                        </ListGroup>   
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What is the confidentiality policy at Two 2 Tango?</Accordion.Header>
                                    <Accordion.Body>
                                    We limit access to your details to our professional matchmakers and share it with your consent.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Is there a refund policy?</Accordion.Header>
                                    <Accordion.Body>
                                    We have a no-refund policy. Please read our terms and conditions before signing up. However, if you are on an international monthly subscription, you can opt-out by giving a 30-day notice.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What payment methods are accepted?</Accordion.Header>
                                    <Accordion.Body>
                                    Our website accepts credit/debit cards and bank transfer.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Is the subscription automatically renewed?</Accordion.Header>
                                    <Accordion.Body> 
                                    International monthly subscriptions are automatically renewed. <br/> The 6 and 12 month memberships are paid upfront and can be renewed at the end of your membership period by contacting our customer support team.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="14" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>How can I cancel my international subscription?</Accordion.Header>
                                    <Accordion.Body>
                                    You can typically cancel your international subscription through your account settings on the website, or by contacting customer support.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="15" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>What happens to my data after the end of my membership?</Accordion.Header>
                                    <Accordion.Body>
                                    Your data is usually retained according to the website’s privacy policy, but you can request the deletion of your account and personal information.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Can I switch my plan after signing up?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, you can upgrade your plan by writing to our customer support team at contactus@two2tango.com.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17" data-aos="fade-up" data-aos-duration="500">
                                    <Accordion.Header>Do membership benefits apply to mobile apps as well?</Accordion.Header>
                                    <Accordion.Body>
                                    We are currently in the process of developing a mobile app and plan to launch it later this year.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                    <Col lg={4} md={12} className='faqs-content-right-col'>
                        <div className='faqs-content-right-col-inner'>
                            <img src={FaqRightImg} alt="Tow To Tango" />
                        </div> 
                    </Col> 
                </Row>
            </Container> 
            <BookConsultation/> 
        </section> 
    </>
  )
}
